import { EmployeeHeader, PageTitle, Spinner } from '@/shared/components';
import { EmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeInParams } from '@/shared/hooks';
import { EmployeeDetail } from '@/shared/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeEditTabs } from './components';

export const EmployeeEdit = () => {
  const navigate = useNavigate();
  const { employee } = useEmployeeInParams();
  const employeeGeneralInformation = employee?.generalInformation;

  const [employeeDetail, setEmployeeDetail] = useState(
    EmployeeDetail.parse({ ...employee }) ?? EmployeeDetail.parse({})
  );

  useEffect(() => {
    setEmployeeDetail(EmployeeDetail.parse({ ...employee }));
  }, [employee]);

  if (!employee) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle text={`Edit Employee`} onBack={() => navigate('/employees')} />
      <EmployeeHeader
        pictureUrl={employeeGeneralInformation?.pictureUrl ?? ''}
        legalFullName={employeeGeneralInformation?.legalFullName ?? ''}
        employeeId={employeeGeneralInformation?.employeeId ?? ''}
        nameInLocalLanguage={
          employeeGeneralInformation?.nameInLocalLanguage ?? ''
        }
        company={employeeGeneralInformation?.company ?? ''}
        department={employeeGeneralInformation?.department ?? ''}
        jobTitle={employeeGeneralInformation?.jobTitle ?? ''}
      />
      <div className='mb-6 mt-6'>
        <EmployeeDetailContext.Provider
          value={{ employeeDetail, setEmployeeDetail }}
        >
          <EmployeeEditTabs />
        </EmployeeDetailContext.Provider>
      </div>
    </>
  );
};
