import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePerformanceReviewTargetMutationVariables = Types.Exact<{
  deletePerformanceReviewTargetId: Types.Scalars['ID']['input'];
}>;


export type DeletePerformanceReviewTargetMutation = { __typename?: 'Mutation', deletePerformanceReviewTarget: boolean };


export const DeletePerformanceReviewTargetDocument = gql`
    mutation DeletePerformanceReviewTarget($deletePerformanceReviewTargetId: ID!) {
  deletePerformanceReviewTarget(id: $deletePerformanceReviewTargetId)
}
    `;
export type DeletePerformanceReviewTargetMutationFn = Apollo.MutationFunction<DeletePerformanceReviewTargetMutation, DeletePerformanceReviewTargetMutationVariables>;

/**
 * __useDeletePerformanceReviewTargetMutation__
 *
 * To run a mutation, you first call `useDeletePerformanceReviewTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerformanceReviewTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerformanceReviewTargetMutation, { data, loading, error }] = useDeletePerformanceReviewTargetMutation({
 *   variables: {
 *      deletePerformanceReviewTargetId: // value for 'deletePerformanceReviewTargetId'
 *   },
 * });
 */
export function useDeletePerformanceReviewTargetMutation(baseOptions?: Apollo.MutationHookOptions<DeletePerformanceReviewTargetMutation, DeletePerformanceReviewTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePerformanceReviewTargetMutation, DeletePerformanceReviewTargetMutationVariables>(DeletePerformanceReviewTargetDocument, options);
      }
export type DeletePerformanceReviewTargetMutationHookResult = ReturnType<typeof useDeletePerformanceReviewTargetMutation>;
export type DeletePerformanceReviewTargetMutationResult = Apollo.MutationResult<DeletePerformanceReviewTargetMutation>;
export type DeletePerformanceReviewTargetMutationOptions = Apollo.BaseMutationOptions<DeletePerformanceReviewTargetMutation, DeletePerformanceReviewTargetMutationVariables>;