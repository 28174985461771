import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { ProbationReviewDetailType } from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { IncompleteReviewModalContent } from './IncompleteReviewModalContent';
import { SubmitReviewModalContent } from './SubmitReviewModalContent';

export const SubmitModal = () => {
  const modalId = uuid();
  const { employeeDetail } = useEmployeeDetailContext();
  const { isMyOwnData, hasProbationReviewModifyAccess } = useEmployeeAccess();
  const { openModal, closeModal } = useModals();
  const { trigger, control, setValue, handleSubmit } =
    useFormContext<ProbationReviewDetailType>();

  const onCloseModal = () => {
    closeModal(modalId);
  };

  const onClick = async () => {
    const isValid = await trigger(['stages', 'expectation']);

    if (!isValid) {
      openModal({
        modalId,
        title: 'The review cannot be submitted.',
        children: <IncompleteReviewModalContent onCloseModal={onCloseModal} />,
      });

      return;
    }

    openModal({
      modalId,
      title: 'Submit Probation Review with Final Result',
      children: (
        <SubmitReviewModalContent
          control={control}
          setValue={setValue}
          onCloseModal={onCloseModal}
          handleSubmit={handleSubmit}
          employeeId={employeeDetail.id}
        />
      ),
    });
  };

  if (isMyOwnData || !hasProbationReviewModifyAccess) {
    return null;
  }

  return (
    <Button className='ml-auto bg-blue-6' onClick={onClick}>
      Submit for Approval
    </Button>
  );
};
