import { Button, ModalFooter } from '@any-ui-react/core';

interface Props {
  onCloseModal: () => void;
}

export const IncompleteReviewModalContent = ({ onCloseModal }: Props) => {
  return (
    <div className='flex flex-col space-y-6'>
      <span>
        Please fill out all mandatory fields to submit the probation review for
        approval.
      </span>

      <ModalFooter>
        <Button className='bg-blue-6' onClick={onCloseModal}>
          OK
        </Button>
      </ModalFooter>
    </div>
  );
};
