import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewValuesMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewValuesInput;
}>;


export type UpdatePerformanceReviewValuesMutation = { __typename?: 'Mutation', updatePerformanceReviewValues: { __typename?: 'PerformanceReviewOutput', id: string } };


export const UpdatePerformanceReviewValuesDocument = gql`
    mutation UpdatePerformanceReviewValues($input: UpdatePerformanceReviewValuesInput!) {
  updatePerformanceReviewValues(input: $input) {
    id
  }
}
    `;
export type UpdatePerformanceReviewValuesMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewValuesMutation, UpdatePerformanceReviewValuesMutationVariables>;

/**
 * __useUpdatePerformanceReviewValuesMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewValuesMutation, { data, loading, error }] = useUpdatePerformanceReviewValuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewValuesMutation, UpdatePerformanceReviewValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewValuesMutation, UpdatePerformanceReviewValuesMutationVariables>(UpdatePerformanceReviewValuesDocument, options);
      }
export type UpdatePerformanceReviewValuesMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewValuesMutation>;
export type UpdatePerformanceReviewValuesMutationResult = Apollo.MutationResult<UpdatePerformanceReviewValuesMutation>;
export type UpdatePerformanceReviewValuesMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewValuesMutation, UpdatePerformanceReviewValuesMutationVariables>;