import { EmergencyContactRelation } from '@/shared/graphql';
import { z } from 'zod';

export const EmergencyContactFormData = z.object({
  name: z.string().nullable().default(null),
  relation: z.nativeEnum(EmergencyContactRelation).nullable().default(null),
  phoneNumber: z.string().nullable().default(null),
  phoneNumberCountryCode: z.string().nullable().default(null),
  remarks: z.string().nullable().default(null),
});
export type EmergencyContactFormDataType = z.infer<
  typeof EmergencyContactFormData
>;
