import {
  ContractType,
  EmployeeRole,
  EmployeeStatus,
  JobHistoryLevel,
  ListEmployeesFilterInput,
} from '@/shared/graphql';
import { WithPagination } from '@/shared/types';
import { z } from 'zod';
import { ExternalAccountsFilter } from './external-accounts';

type Constraints = WithPagination<ListEmployeesFilterInput>;

export const EmployeeListParser = z.object({
  id: z.string().nullish().default(''),
  name: z.string().nullish().default(''),
  page: z.number().catch(1),
  keyword: z.string().nullish().catch(''),
  statuses: z.array(z.nativeEnum(EmployeeStatus)).nullish().catch([]),
  hiredDate: z
    .object({
      start: z.string().nullable().default(''),
      end: z.string().nullable().default(''),
    })
    .nullable()
    .optional()
    .catch(null),
  employmentEndDate: z
    .object({
      start: z.string().nullable().default(''),
      end: z.string().nullable().default(''),
    })
    .nullable()
    .optional()
    .catch(null),
  contractTypes: z.array(z.nativeEnum(ContractType)).optional().catch([]),
  levels: z.array(z.nativeEnum(JobHistoryLevel)).optional().catch([]),
  roles: z.array(z.nativeEnum(EmployeeRole)).optional().catch([]),
  companyIds: z.array(z.string()).optional().catch([]),
  locationIds: z.array(z.string()).optional().catch([]),
  divisionIds: z.array(z.string()).optional().catch([]),
  businessUnitIds: z.array(z.string()).optional().catch([]),
  managerIds: z.array(z.string()).optional().catch([]),
  departmentIds: z.array(z.string()).optional().catch([]),
  jobIds: z.array(z.string()).optional().catch([]),
  nationalityIds: z.array(z.string()).optional().catch([]),
  isOnSecondment: z.boolean().nullish().default(false),
  isLeave: z.boolean().nullish().default(false),
  isOpenFilter: z.boolean().optional().default(false),
  managerIncludesAllConnections: z.boolean().nullish().default(false),
  externalAccount: ExternalAccountsFilter.nullable().default(
    ExternalAccountsFilter.parse({})
  ),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type EmployeeListFilterType = z.infer<typeof EmployeeListParser>;
