import { EmployeeHeader, PageTitle, Spinner } from '@/shared/components';
import { useGetCurrentEmployeeQuery } from '@/shared/graphql';
import { Button } from '@mantine/core';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StringUtils } from 'src/utils/string.utils';
import { PerformanceReviewStatusBadge } from '../components';
import { PerformanceReviewEditTabs } from './components';
import { PerformanceReviewEmployeeContext } from './context/PerformanceReviewContext';
import { usePerformanceReviewQuery } from './graphql';
import { PerformanceReviewType } from './types';

export const PerformanceReviewEdit = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const { data } = usePerformanceReviewQuery({
    skip: !params.id,
    variables: {
      performanceReviewId: params.id ?? '',
    },
  });

  const { data: currentEmployee, loading: currentUserLoading } =
    useGetCurrentEmployeeQuery();

  const form = useForm<PerformanceReviewType>({
    defaultValues: data?.performanceReview ?? undefined,
  });

  useEffect(() => {
    if (data?.performanceReview) {
      form.reset(data?.performanceReview);
    }
  }, [data?.performanceReview, form]);

  if (currentUserLoading) {
    return <Spinner />;
  }

  if (!data?.performanceReview) {
    return null;
  }

  return (
    <PerformanceReviewEmployeeContext.Provider
      value={{
        employee: data?.performanceReview?.employee,
        currentEmployee: currentEmployee?.currentEmployee,
      }}
    >
      <PageTitle
        text='Edit Performance Review'
        onBack={() => navigate('/performance-review')}
      />
      <div className='mb-10 flex flex-col space-y-4'>
        <EmployeeHeader
          pictureUrl={data?.performanceReview?.employee.pictureUrl}
          legalFullName={StringUtils.withFallback(
            data?.performanceReview?.employee.generalInformation.legalFullName
          )}
          employeeId={StringUtils.withFallback(
            data?.performanceReview?.employee.id
          )}
          nameInLocalLanguage={StringUtils.withFallback(
            data?.performanceReview?.employee.generalInformation
              .nameInLocalLanguage
          )}
          company={StringUtils.withFallback(
            data?.performanceReview?.employee.currentContract?.company.name
          )}
          department={StringUtils.withFallback(
            data?.performanceReview?.employee.currentPosition?.department.name
          )}
          jobTitle={StringUtils.withFallback(
            data?.performanceReview?.employee.currentPosition?.jobTitle
          )}
          extra={
            <div className='flex space-x-2'>
              <span>
                {data?.performanceReview?.period.year} -{' '}
                {data?.performanceReview?.period.quarter.replace('_', '/')}
              </span>
              <PerformanceReviewStatusBadge
                status={data?.performanceReview?.status}
              />
            </div>
          }
          aside={
            <Link to={`/employees/edit/${data.performanceReview.employeeId}`}>
              <Button variant='default'>Employee Detail</Button>
            </Link>
          }
        />
        <FormProvider {...form}>
          <PerformanceReviewEditTabs />
        </FormProvider>
      </div>
    </PerformanceReviewEmployeeContext.Provider>
  );
};
