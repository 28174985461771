import { CompanyProbationReviewTermMode } from '@/shared/graphql';
import { create, enforce, test } from 'vest';

export interface CompanyFormData {
  name: string;
  countryId: string;
  probationReviewTerm: {
    mode: CompanyProbationReviewTermMode;
    lengthInDay: number;
    lengthInMonth: number;
  };
  probationReviewTimes: number;
  enabled: boolean;
  googleOrgUnitPath: string;
}

export const companyFormValidationSuite = create(
  ({
    name,
    countryId,
    probationReviewTimes,
    probationReviewTerm,
    googleOrgUnitPath,
  }: CompanyFormData) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
    test('countryId', 'Country is required', () => {
      enforce(countryId).isNotEmpty();
    });
    test('probationReviewTimes', 'Probation Review Times is required', () => {
      enforce(probationReviewTimes).isNotEmpty();
    });
    test(
      'probationReviewTerm.mode',
      'Probation Review Length is required',
      () => {
        enforce(probationReviewTerm.mode).isNotEmpty();
      }
    );
    test('googleOrgUnitPath', 'Google Org Unit Path is required', () => {
      enforce(googleOrgUnitPath).isNotEmpty();
    });
  }
);
