import { DatePicker } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { ProbationReviewDetailType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Select } from '@any-ui-react/select';
import { Button, Textarea } from '@mantine/core';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { ReadOnlyInput } from '../probation-details';

interface Props {
  index: number;
  onRemove: () => void;
  deletable: boolean;
  isSubmitted: boolean;
  reviewerName?: string;
}

export const ProbationReviewStageFields = ({
  index,
  onRemove,
  deletable,
  isSubmitted,
  reviewerName = '',
}: Props) => {
  const { employeeDetail } = useEmployeeDetailContext();
  const { isMyOwnData } = useEmployeeAccess();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ProbationReviewDetailType>();

  return (
    <div className='mb-5 flex flex-col space-y-6'>
      <div className='flex w-full space-x-4'>
        <div className='flex-1'>
          <Controller
            control={control}
            name={`stages.${index}.reviewerId`}
            render={({ field: { onChange, ...field } }) => {
              if (isSubmitted) {
                return (
                  <ReadOnlyInput
                    label='Reviewer'
                    value={reviewerName}
                    required
                  />
                );
              }

              return (
                <Select
                  {...field}
                  required
                  label='Reviewer'
                  placeholder='Please Select'
                  error={errors.stages?.[index]?.reviewerId?.message}
                  options={employeeDetail.managers.map((manager) => ({
                    value: manager.id,
                    label: manager.name,
                  }))}
                  onChange={(v) => onChange(v?.value)}
                />
              );
            }}
          />
        </div>

        <div className='flex-1'>
          <Controller
            control={control}
            name={`stages.${index}.deadline`}
            render={({ field: { value, ...field } }) => {
              if (isSubmitted) {
                return (
                  <ReadOnlyInput
                    label='Review Deadline'
                    value={DateUtils.formatDate(value, 'MMM dd, yyyy')}
                    required
                  />
                );
              }

              return (
                <DatePicker
                  {...field}
                  value={value || undefined}
                  dateFormat='MMM dd, yyyy'
                  required
                  label='Review Deadline'
                  placeholder='Please Select'
                  disabled={isMyOwnData}
                  error={errors.stages?.[index]?.deadline?.message}
                />
              );
            }}
          />
        </div>
      </div>

      <Textarea
        {...register(`stages.${index}.selfFeedback`)}
        minRows={5}
        required
        label='Self feedback'
        error={errors.stages?.[index]?.selfFeedback?.message}
        disabled={isSubmitted || !isMyOwnData}
        classNames={{
          input: classNames({ '!text-gray-9': isSubmitted || !isMyOwnData }),
        }}
      />

      <Textarea
        {...register(`stages.${index}.reviewerFeedback`)}
        minRows={5}
        required
        label={`Reviewer's feedback`}
        error={errors.stages?.[index]?.reviewerFeedback?.message}
        disabled={isSubmitted || isMyOwnData}
        classNames={{
          input: classNames({ '!text-gray-9': isSubmitted || isMyOwnData }),
        }}
      />

      {deletable && !isSubmitted && (
        <div className='flex w-full justify-end'>
          <Button
            variant='outline'
            className='border-red-6 text-red-6'
            onClick={onRemove}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};
