import { createContext, useContext } from 'react';
import { GetCurrentEmployeeQuery } from 'src/graphql/query';
import { PerformanceReviewEmployee } from '../types';

export type PerformanceReviewEmployeeContextType = {
  employee: PerformanceReviewEmployee | null;
  currentEmployee?: GetCurrentEmployeeQuery['currentEmployee'] | null;
  //   setEmployee: (employee: PerformanceReviewEmployee | null) => void;
};

export const PerformanceReviewEmployeeContext =
  createContext<PerformanceReviewEmployeeContextType>({
    employee: null,
    currentEmployee: null,
    // setEmployee: () => console.warn('No Employee Detail provider'),
  });

export const usePerformanceReviewEmployee =
  (): PerformanceReviewEmployeeContextType =>
    useContext(PerformanceReviewEmployeeContext);
