import { EmployeeExternalAccountStatus } from '@/shared/graphql';
import { Badge } from '@any-ui-react/core';
import { useMemo } from 'react';

const ExternalAccountStatusLabels = {
  [EmployeeExternalAccountStatus.Active]: 'Active',
  [EmployeeExternalAccountStatus.Inactive]: 'Deactivated',
  [EmployeeExternalAccountStatus.NotExisting]: 'Not invited',
  [EmployeeExternalAccountStatus.Suspended]: 'Suspended',
};

interface Props {
  status?: EmployeeExternalAccountStatus | null;
}

export const ExternalAccountStatusBadge = ({ status }: Props) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case EmployeeExternalAccountStatus.Active:
        return 'blue';
      case EmployeeExternalAccountStatus.Inactive:
        return 'red';
      case EmployeeExternalAccountStatus.NotExisting:
        return 'yellow';
      case EmployeeExternalAccountStatus.Suspended:
        return 'gray';
      default:
        return 'gray';
    }
  }, [status]);

  if (!status) {
    return null;
  }

  return (
    <Badge color={statusColor}>{ExternalAccountStatusLabels[status]}</Badge>
  );
};
