import { Table } from '@/shared/components';
import {
  ReportNumberOfEmployeeAllSubtotal,
  ReportNumberOfEmployeeAllYearMonth,
  useReportNumberOfEmployeeAllQuery,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { NumberOfEmployeesFilterType } from '@/shared/types';
import { useMemo } from 'react';
import { TableUtils } from '../../utils';

type ClientReportNumberOfEmployeeAllYearMonth = {
  metric: string;
  joined: number;
  left: number;
  total: number;
  subtotal: number;
};

export const NumberOfEmployeesAllTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const metrics = useMemo(() => ['total', 'joined', 'left'], []);

  const { data, loading } = useReportNumberOfEmployeeAllQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = TableUtils.generateColumnNames(
    Number(filters.current.year)
  );

  const tableData: ClientReportNumberOfEmployeeAllYearMonth[] = useMemo(
    () =>
      metrics.map((metric: string) => {
        const yearMonthData = Object.fromEntries(
          yearMonths.map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeAll.items.find(
              (item) => item.yearMonth === yearMonth
            )?.[metric as keyof ReportNumberOfEmployeeAllYearMonth] ?? 0,
          ])
        );

        return {
          metric: metric.charAt(0).toUpperCase() + metric.slice(1),
          joined: yearMonthData['joined'] ?? 0,
          left: yearMonthData['left'] ?? 0,
          total: yearMonthData['total'] ?? 0,
          ...yearMonthData,
          subtotal:
            data?.reportNumberOfEmployeeAll.subtotal?.[
              metric as keyof ReportNumberOfEmployeeAllSubtotal
            ],
        };
      }),
    [
      data?.reportNumberOfEmployeeAll.items,
      data?.reportNumberOfEmployeeAll.subtotal,
      metrics,
      yearMonths,
    ]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => <span />,
        accessor: 'metric',
        cellRender: (row: ClientReportNumberOfEmployeeAllYearMonth) =>
          row.metric,
      },
      ...yearMonths.map((yearMonth) => ({
        headerRender: () => (
          <div className='flex w-full justify-end'> {yearMonth}</div>
        ),
        accessor: yearMonth,
        cellRender: (row: ClientReportNumberOfEmployeeAllYearMonth) => (
          <div className='w-full text-right'>
            {row[yearMonth as keyof ClientReportNumberOfEmployeeAllYearMonth]}
          </div>
        ),
      })),
      {
        headerRender: () => 'Subtotal',
        accessor: 'subtotal',
        cellRender: (row: ClientReportNumberOfEmployeeAllYearMonth) => (
          <span>{row.subtotal}</span>
        ),
      },
    ],
    [yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeAllYearMonth>
        layout={columns}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
