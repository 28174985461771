import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import {
  PerformanceReviewListFilter,
  PerformanceReviewListTable,
  usePerformanceReviewListFilter,
} from './components';
import { useListPerformanceReviewsQuery } from './graphql';

export const PerformanceReviewList = () => {
  const [, scrollTo] = useWindowScroll();
  const { filters } = usePerformanceReviewListFilter();
  const { page, ...currentFilter } = filters.current;

  const { data, loading } = useListPerformanceReviewsQuery({
    variables: {
      pageNumber: page,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: {
        period: {
          year: currentFilter.year,
          quarter: currentFilter.quarter,
        },
        keyword: currentFilter.keyword,
        status: currentFilter.status,
        contractTypes: currentFilter.contractTypes,
        companyIds: currentFilter.companyIds,
        locationIds: currentFilter.locationIds,
        businessUnitIds: currentFilter.businessUnitIds,
        departmentIds: currentFilter.departmentIds,
        levels: currentFilter.levels,
        showDirectReportsOnly: currentFilter.showDirectReportsOnly,
      },
    },
  });

  return (
    <>
      <PageTitle text='Performance Review' />
      <Card withPadding={false}>
        <PerformanceReviewListFilter filters={filters} />
        <PerformanceReviewListTable
          data={data?.listPerformanceReviews.items ?? []}
          loading={loading}
          page={page}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={1}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        count={data?.listPerformanceReviews.totalCount || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
