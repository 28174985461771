import { Input } from '@mantine/core';

interface Props {
  label?: string;
  value: string;
  required?: boolean;
}

export const ReadOnlyInput = ({ value, label, required }: Props) => {
  return (
    <div className='flex flex-col space-y-1'>
      <Input.Label required={required}>{label}</Input.Label>
      <span className='font-semibold'>{value}</span>
    </div>
  );
};
