import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewTargetNumericOtherMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewTargetNumericOtherInput;
}>;


export type UpdatePerformanceReviewTargetNumericOtherMutation = { __typename?: 'Mutation', updatePerformanceReviewTargetNumericOther: boolean };


export const UpdatePerformanceReviewTargetNumericOtherDocument = gql`
    mutation UpdatePerformanceReviewTargetNumericOther($input: UpdatePerformanceReviewTargetNumericOtherInput!) {
  updatePerformanceReviewTargetNumericOther(input: $input)
}
    `;
export type UpdatePerformanceReviewTargetNumericOtherMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewTargetNumericOtherMutation, UpdatePerformanceReviewTargetNumericOtherMutationVariables>;

/**
 * __useUpdatePerformanceReviewTargetNumericOtherMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewTargetNumericOtherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewTargetNumericOtherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewTargetNumericOtherMutation, { data, loading, error }] = useUpdatePerformanceReviewTargetNumericOtherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewTargetNumericOtherMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewTargetNumericOtherMutation, UpdatePerformanceReviewTargetNumericOtherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewTargetNumericOtherMutation, UpdatePerformanceReviewTargetNumericOtherMutationVariables>(UpdatePerformanceReviewTargetNumericOtherDocument, options);
      }
export type UpdatePerformanceReviewTargetNumericOtherMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewTargetNumericOtherMutation>;
export type UpdatePerformanceReviewTargetNumericOtherMutationResult = Apollo.MutationResult<UpdatePerformanceReviewTargetNumericOtherMutation>;
export type UpdatePerformanceReviewTargetNumericOtherMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewTargetNumericOtherMutation, UpdatePerformanceReviewTargetNumericOtherMutationVariables>;