import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerformanceReviewTargetNumericOtherMutationVariables = Types.Exact<{
  input: Types.CreatePerformanceReviewTargetNumericOtherInput;
}>;


export type CreatePerformanceReviewTargetNumericOtherMutation = { __typename?: 'Mutation', createPerformanceReviewTargetNumericOther: boolean };


export const CreatePerformanceReviewTargetNumericOtherDocument = gql`
    mutation CreatePerformanceReviewTargetNumericOther($input: CreatePerformanceReviewTargetNumericOtherInput!) {
  createPerformanceReviewTargetNumericOther(input: $input)
}
    `;
export type CreatePerformanceReviewTargetNumericOtherMutationFn = Apollo.MutationFunction<CreatePerformanceReviewTargetNumericOtherMutation, CreatePerformanceReviewTargetNumericOtherMutationVariables>;

/**
 * __useCreatePerformanceReviewTargetNumericOtherMutation__
 *
 * To run a mutation, you first call `useCreatePerformanceReviewTargetNumericOtherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerformanceReviewTargetNumericOtherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerformanceReviewTargetNumericOtherMutation, { data, loading, error }] = useCreatePerformanceReviewTargetNumericOtherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerformanceReviewTargetNumericOtherMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerformanceReviewTargetNumericOtherMutation, CreatePerformanceReviewTargetNumericOtherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerformanceReviewTargetNumericOtherMutation, CreatePerformanceReviewTargetNumericOtherMutationVariables>(CreatePerformanceReviewTargetNumericOtherDocument, options);
      }
export type CreatePerformanceReviewTargetNumericOtherMutationHookResult = ReturnType<typeof useCreatePerformanceReviewTargetNumericOtherMutation>;
export type CreatePerformanceReviewTargetNumericOtherMutationResult = Apollo.MutationResult<CreatePerformanceReviewTargetNumericOtherMutation>;
export type CreatePerformanceReviewTargetNumericOtherMutationOptions = Apollo.BaseMutationOptions<CreatePerformanceReviewTargetNumericOtherMutation, CreatePerformanceReviewTargetNumericOtherMutationVariables>;