import {
  ContractText,
  EMPTY_DEFAULT,
  ExternalAccountStatusBadge,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import {
  EmployeeExternalAccountType,
  EmployeeListSortField,
  EmployeeStatus,
  ListEmployeesSortInput,
} from '@/shared/graphql';
import { FilterProps, useResendEmailInvitation } from '@/shared/hooks';
import { TableSelection } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Badge, Button } from '@any-ui-react/core';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EmployeeList, EmployeeListItem } from '../../types';
import { EmployeeListExport } from '../employee-list-export';
import { EmployeeListFilterType } from '../employee-list-filter/EmployeeList.filters';
import { SortableHeader } from './SortableHeader';

interface UserTableProps {
  employees?: EmployeeList;
  loading: boolean;
  page: number;
  filters: FilterProps<EmployeeListFilterType>;
  sort: ListEmployeesSortInput | null;
  setSort: (sort: ListEmployeesSortInput | null) => void;
}

export const EmployeeListTable = ({
  employees,
  loading,
  page,
  filters,
  sort,
  setSort,
}: UserTableProps) => {
  const { resendEmail, resendLoading } = useResendEmailInvitation();
  const [selection, setSelection] = useState<TableSelection<EmployeeListItem>>({
    items: [],
    allPages: false,
  });

  const layout: TableLayout<EmployeeListItem>[] = useMemo(
    () => [
      {
        headerRender: () => 'Preferred Full Name',
        tdClassName: '!max-w-[182px] !min-w-[182px]',
        thClassName: '!max-w-[182px] !min-w-[182px]',
        accessor: 'preferredFullName',
        truncated: true,
        sticky: true,
        cellRender: (employee) => {
          return (
            <Link to={`/employees/edit/${employee.id}`}>
              <span className='text-blue-6 hover:cursor-pointer'>
                {employee.generalInformation.preferredFullName}
              </span>
            </Link>
          );
        },
      },
      {
        headerRender: () => 'ID',
        accessor: 'employeeId',
        truncated: true,
        sticky: true,
        cellRender: ({ id }) => {
          return <span>{id}</span>;
        },
      },
      {
        headerRender: () => 'Status',
        accessor: 'status',
        cellRender: ({ status }) => {
          const getStatusColor = (status: EmployeeStatus) => {
            switch (status) {
              case EmployeeStatus.Active:
                return 'blue';
              case EmployeeStatus.Inactive:
                return 'red';
              case EmployeeStatus.PreOnboarding:
                return 'gray';
              default:
                return 'gray';
            }
          };
          return (
            <Badge className='capitalize' color={getStatusColor(status)}>
              {StringUtils.capitalizeAll(status, '_')}
            </Badge>
          );
        },
      },

      {
        headerRender: () => 'Legal Full Name',
        accessor: 'legalFullName',
        cellRender: ({ generalInformation }) => {
          return <span>{generalInformation.legalFullName}</span>;
        },
      },
      {
        headerRender: () => 'Email',
        accessor: 'email',
        cellRender: ({ generalInformation }) => {
          return (
            <span>
              {generalInformation.companyEmail.fullAddress || EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => (
          <SortableHeader
            label='Hired Date'
            field={EmployeeListSortField.HiredDate}
            sort={sort}
            setSort={setSort}
          />
        ),
        accessor: 'hiredDate',
        cellRender: ({ generalInformation }) =>
          generalInformation.hiredDate
            ? DateUtils.formatDate(generalInformation.hiredDate, 'MMM dd, yyyy')
            : EMPTY_DEFAULT,
      },
      {
        headerRender: () => 'Contract Type',
        accessor: 'contractType',
        cellRender: ({ currentContract }) =>
          currentContract?.contractType
            ? String(ContractText[currentContract?.contractType])
            : EMPTY_DEFAULT,
      },
      {
        headerRender: () => 'Office Location',
        accessor: 'officeLocation',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.location.name),
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ currentContract }) =>
          StringUtils.withFallback(currentContract?.company.name),
      },
      {
        headerRender: () => 'Business Unit',
        accessor: 'businessUnit',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.businessUnit.name),
      },
      {
        headerRender: () => (
          <SortableHeader
            label='Department'
            field={EmployeeListSortField.Department}
            sort={sort}
            setSort={setSort}
          />
        ),
        accessor: 'department',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.department.name),
      },
      {
        headerRender: () => 'Job Title',
        accessor: 'jobTitle',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
      {
        headerRender: () => 'Job',
        accessor: 'job',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
      {
        headerRender: () => (
          <SortableHeader
            label='Level'
            field={EmployeeListSortField.Level}
            sort={sort}
            setSort={setSort}
          />
        ),
        accessor: 'level',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.level),
      },
      {
        headerRender: () => 'Primary Manager',
        accessor: 'primaryManager',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(
            generalInformation.primaryManager?.generalInformation
              .preferredFullName
          ),
      },
      {
        headerRender: () => 'Dotted Line Manager',
        accessor: 'dottedLineManager',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(
            generalInformation.dottedLineManager?.generalInformation
              .preferredFullName
          ),
      },
      {
        headerRender: () => 'Nationality',
        accessor: 'nationality',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(generalInformation.nationality.name),
      },
      {
        headerRender: () => 'Google',
        accessor: 'google',
        cellRender: ({ externalAccounts }) => {
          const googleAccount = externalAccounts?.find(
            (account) =>
              account.accountType === EmployeeExternalAccountType.Google
          );

          return <ExternalAccountStatusBadge status={googleAccount?.status} />;
        },
      },
      {
        headerRender: () => 'greenhouse',
        accessor: 'isGreenhouseEnabled',
        cellRender: ({ externalAccounts }) => {
          const greenhouseAccount = externalAccounts?.find(
            (account) =>
              account.accountType === EmployeeExternalAccountType.Greenhouse
          );

          return (
            <ExternalAccountStatusBadge status={greenhouseAccount?.status} />
          );
        },
      },
      {
        headerRender: () => (
          <SortableHeader
            label='Last Working Date'
            field={EmployeeListSortField.LastWorkingDate}
            sort={sort}
            setSort={setSort}
          />
        ),
        accessor: 'lastWorkingDate',
        cellRender: ({ offBoarding }) =>
          DateUtils.formatDate(offBoarding?.lastWorkingDate, 'MMM dd, yyyy'),
      },
      {
        headerRender: () => (
          <SortableHeader
            label='Termination Date'
            field={EmployeeListSortField.LastWorkingDate}
            sort={sort}
            setSort={setSort}
          />
        ),
        accessor: 'terminationDate',
        cellRender: ({ offBoarding }) =>
          DateUtils.formatDate(offBoarding?.employmentEndDate, 'MMM dd, yyyy'),
      },
      {
        headerRender: () => 'Reason For Leaving',
        accessor: 'reasonForLeaving',
        cellRender: ({ offBoarding }) =>
          StringUtils.withFallback(offBoarding?.reasonForLeaving?.name),
      },
    ],
    [setSort, sort]
  );

  return (
    <Table<EmployeeListItem>
      page={page}
      numOfStickyCols={2}
      layout={layout}
      loading={loading}
      data={employees || []}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
      allowFloatingHeader
      selection={selection}
      onSelectionChange={setSelection}
      selectable={{
        byField: 'id',
        total: employees?.length || 0,
        aside: (
          <div className='flex space-x-4'>
            <Button
              className='bg-blue-6'
              loading={resendLoading}
              onClick={() =>
                resendEmail({ ids: selection.items.map((item) => item.id) })
              }
            >
              Resend Invitation
            </Button>
            <EmployeeListExport selection={selection} filters={filters} />
          </div>
        ),
      }}
    />
  );
};
