import { Card, PageTitle } from '@/shared/components';
import { Select } from '@any-ui-react/select';
import { useMemo, useState } from 'react';
import { RouterUtils } from 'src/utils/router.utils';
import { chartsData } from './charts.data';

const chartOptions = chartsData.map((data) => ({
  value: data.id,
  label: data.label,
}));

export const OrganizationChart = () => {
  const [activeChart, setActiveChart] = useState(chartsData[0].id);
  const selectedChart = useMemo(
    () => chartsData.find((chart) => chart.id === activeChart),
    [activeChart]
  );

  return (
    <>
      <PageTitle text='Organization Chart' />
      <Card>
        <Select
          value={activeChart}
          options={chartOptions}
          onChange={(v) => v && setActiveChart(v.value)}
          className='w-full md:w-1/2 xl:w-1/3'
        />

        <span
          className='mt-4 block cursor-pointer text-link underline'
          onClick={() =>
            RouterUtils.externalRedirect(selectedChart?.link, true)
          }
        >
          Go to view Organimi organization chart
        </span>
      </Card>

      <Card withPadding={false} className='mt-6'>
        <iframe
          title={selectedChart?.label}
          src={selectedChart?.embedLink}
          width='100%'
          height={600}
          loading='lazy'
        />
      </Card>
    </>
  );
};
