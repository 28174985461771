import { EmployeeRole } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeInfoFilter = z.object({
  managerIds: z.array(z.string()).catch([]),
  jobIds: z.array(z.string()).catch([]),
  nationalityIds: z.array(z.string()).catch([]),
  roles: z.array(z.nativeEnum(EmployeeRole)).catch([]),
  managerIncludesAllConnections: z.boolean().default(false),
  hiredDate: z
    .object({
      start: z.string().nullable().default(''),
      end: z.string().nullable().default(''),
    })
    .nullable()
    .optional()
    .catch(null),
  employmentEndDate: z
    .object({
      start: z.string().nullable().default(''),
      end: z.string().nullable().default(''),
    })
    .nullable()
    .optional()
    .catch(null),
});
export type EmployeeInfoFilterType = z.infer<typeof EmployeeInfoFilter>;
