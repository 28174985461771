import { format, isAfter, isBefore, isDate, isValid, parseISO } from 'date-fns';
import { formatInTimeZone, OptionsWithTZ } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

import { EMPTY_DEFAULT } from '../components';
import { PerformanceReviewQuarter } from '../graphql';
import { TimezoneUtils } from './timezone.utils';

export type RangeBoundary = 'start' | 'end' | 'startEnd';
export type RangePeriod = 'past' | 'future';
export interface DateFormatOptions {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

export class DateUtils {
  // Details
  // https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/3102605334/Date+and+Times
  static readonly FORMATS = {
    // Days of week
    EEEE: 'EEEE',
    // March 1st, 2017
    PPP: 'PPP',
    // March 1st, 2017 13:00
    PPPHHmm: 'PPP HH:mm',
    // 2017/03/01 13:00
    PHHmm: 'P HH:mm',
    // 2017/03/01
    P: 'P',
  };

  static getClosestDate(
    date1: Date | null,
    date2: Date | null,
    period: RangePeriod = 'past'
  ) {
    if (!isDate(date1) || !isDate(date2)) {
      return isDate(date1) ? date1 : isDate(date2) ? date2 : null;
    }
    return period === 'future'
      ? isBefore(date1 as Date, date2 as Date)
        ? date2
        : date1
      : isAfter(date1 as Date, date2 as Date)
      ? date2
      : date1;
  }

  static formatDate(
    date: number | Date | string | null,
    formatting?: string,
    language = 'en-US'
  ): string {
    date = typeof date === 'string' ? parseISO(date) : date;
    return date && isValid(date)
      ? format(date, formatting || DateUtils.FORMATS.PHHmm, {
          locale: enUS,
        })
      : EMPTY_DEFAULT;
  }

  static formatDateInTimeZone(
    date: Date | string | number,
    extras?: {
      timeZone?: string;
      language?: string;
      formatStr?: string;
      options?: OptionsWithTZ;
    }
  ) {
    const {
      timeZone = TimezoneUtils.getCurrentTimezone(),
      formatStr = DateUtils.FORMATS.PHHmm,
      options,
    } = extras || {};

    try {
      return formatInTimeZone(date, timeZone, formatStr, {
        locale: enUS,
        ...options,
      });
    } catch (error) {
      return '-';
    }
  }

  static getMinuteDifference(startDate: Date, endDate: Date): number {
    return (endDate.getTime() - startDate.getTime()) / 60000;
  }

  static formatDatetime = (rawDate: string) => {
    try {
      return format(new Date(rawDate), 'PP HH:mm:ss');
    } catch (e) {
      console.error(e);
      return '-';
    }
  };

  static getCurrentPerformanceReviewQuarter = (): PerformanceReviewQuarter => {
    const month = new Date().getMonth();
    if (month < 6) {
      return PerformanceReviewQuarter.Q1Q2;
    }
    return PerformanceReviewQuarter.Q3Q4;
  };
}
