import { Table } from '@/shared/components';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeBusinessUnitQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';

type ClientReportNumberOfEmployeeBusinessUnit = {
  businessUnit: {
    name: string;
  };
  total: number;
  yearMonth: string;
};

export const NumberOfEmployeesBusinessUnitTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeBusinessUnitQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = data?.reportNumberOfEmployeeBusinessUnit.yearMonthList;
  const businessUNits = useMemo(
    () =>
      data?.reportNumberOfEmployeeBusinessUnit.items.map(
        (item) => item.businessUnit.name
      ),
    [data?.reportNumberOfEmployeeBusinessUnit.items]
  );

  const tableData: ClientReportNumberOfEmployeeBusinessUnit[] = useMemo(
    () =>
      [...new Set(businessUNits)].map((businessUnit: string) => ({
        businessUnit: {
          name: businessUnit,
        },
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeBusinessUnit.items.find(
              (item) =>
                item.yearMonth === yearMonth &&
                item.businessUnit.name === businessUnit
            )?.total ?? 0,
          ])
        ),
      })),
    [businessUNits, data?.reportNumberOfEmployeeBusinessUnit.items, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => null,
        accessor: 'businessUnit',
        cellRender: (row: ClientReportNumberOfEmployeeBusinessUnit) => (
          <span>{row.businessUnit.name}</span>
        ),
      },
      ...(yearMonths || []).map((yearMonth) => ({
        headerRender: () => yearMonth,
        accessor: yearMonth,
        cellRender: (row: ClientReportNumberOfEmployeeBusinessUnit) => (
          <div className='w-full text-right'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeBusinessUnit
            ].toString()}
          </div>
        ),
      })),
    ],
    [yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeBusinessUnit>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
