import { toast } from '@/shared/components';
import { useSendInvitationEmailsMutation } from '@/shared/graphql';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';

export const useResendEmailInvitation = () => {
  const [sendMail, { loading }] = useSendInvitationEmailsMutation();

  const resendEmail = useCallback(
    async ({ ids }: { ids: string[] }) => {
      await sendMail({
        variables: {
          input: {
            employeeIds: ids,
          },
        },
        onCompleted: () => toast.success(),
        onError: (error: ApolloError) => toast.error(error.message),
      });
    },
    [sendMail]
  );

  return { resendEmail, resendLoading: loading };
};
