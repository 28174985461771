import { EmployeeAddFormDataType } from '@/shared/types';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import isMatch from 'validator/lib/matches';
import { create, enforce, skipWhen, test } from 'vest';
import 'vest/enforce/isURL';

enforce.extend({ isEmail, isUrl, isMatch });

export const addFormValidator = create(
  ({
    preferredFullName,
    legalFullName,
    nationalityId,
    primaryManagerId,
    emailDomain,
    emailUsername,
    hiringSource,
    hiredDate,
    greenhouseLink,
    contractType,
    companyId,
    locationId,
    businessUnitId,
    departmentId,
    jobId,
    jobTitle,
    level,
    personalEmail,
    gender,
    dateOfBirth,
    residentialCountryId,
    idIssuedDate,
    idExpiryDate,
    passportName,
    passportIssuedPlace,
    passportIssuedDate,
    passportExpiryDate,
    placeOfBirth,
  }: EmployeeAddFormDataType) => {
    test('preferredFullName', 'Preferred Full Name is required', () => {
      enforce(preferredFullName).isNotEmpty();
    });

    test('legalFullName', 'Legal Full Name is required', () => {
      enforce(legalFullName).isNotEmpty();
    });

    test('nationalityId', 'Nationality is required', () => {
      enforce(nationalityId).isNotEmpty();
    });

    test('primaryManagerId', 'Primary Manager is required', () => {
      enforce(primaryManagerId).isNotEmpty();
    });

    test('emailUsername', 'Email is required', () => {
      enforce(emailUsername).isNotEmpty();
    });

    test('emailDomain', 'Domain is required', () => {
      enforce(emailDomain).isNotEmpty();
    });

    test('hiringSource', 'Hiring Source is required', () => {
      enforce(hiringSource).isNotEmpty();
    });

    test('hiredDate', 'Hired Date is required', () => {
      enforce(hiredDate).isNotEmpty();
    });

    test('greenhouseLink', 'Greenhouse Link is required', () => {
      enforce(greenhouseLink).isNotEmpty();
    });

    test('greenhouseLink', 'Greenhouse Link is required', () => {
      enforce(greenhouseLink).isURL();
    });

    test('contractType', 'Contract Type is required', () => {
      enforce(contractType).isNotEmpty();
    });

    test('companyId', 'Company is required', () => {
      enforce(companyId).isNotEmpty();
    });

    test('locationId', 'Location is required', () => {
      enforce(locationId).isNotEmpty();
    });

    test('businessUnitId', 'Business Unit is required', () => {
      enforce(businessUnitId).isNotEmpty();
    });

    test('departmentId', 'Department is required', () => {
      enforce(departmentId).isNotEmpty();
    });

    test('jobId', 'Job is required', () => {
      enforce(jobId).isNotEmpty();
    });

    test('jobTitle', 'Job Title is required', () => {
      enforce(jobTitle).isNotEmpty();
    });

    test('level', 'Level is required', () => {
      enforce(level).isNotEmpty();
    });

    test('gender', 'Gender is required', () => {
      enforce(gender).isNotEmpty();
    });

    test('residentialCountryId', 'Residential Location is required', () => {
      enforce(residentialCountryId).isNotEmpty();
    });

    test('dateOfBirth', 'Date of Birth is required', () => {
      enforce(dateOfBirth).isNotEmpty();
    });

    skipWhen(!personalEmail, () => {
      test('personalEmail', 'Personal Email is not valid', () => {
        enforce(personalEmail).isEmail();
      });
    });

    test('dateOfBirth', 'Date of Birth is required', () => {
      enforce(dateOfBirth).isNotEmpty();
    });
  }
);
