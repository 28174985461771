import { PerformanceReviewTargetNumericCategory } from '@/shared/graphql';
import { Select, SelectProps } from '@any-ui-react/core';

const orderedKPICategories = [
  PerformanceReviewTargetNumericCategory.GrossProfit,
  PerformanceReviewTargetNumericCategory.Revenue,
  PerformanceReviewTargetNumericCategory.OperatingProfit,
  PerformanceReviewTargetNumericCategory.Other,
];

interface KPICategorySelectProps extends Omit<SelectProps, 'data'> {}

export const KPICategoryLabels = {
  [PerformanceReviewTargetNumericCategory.GrossProfit]: 'Gross Profit',
  [PerformanceReviewTargetNumericCategory.OperatingProfit]:
    'Operating Profit',
  [PerformanceReviewTargetNumericCategory.Other]: 'Others',
  [PerformanceReviewTargetNumericCategory.Revenue]: 'Revenue',
};

export const KPICategorySelect = ({ ...props }: KPICategorySelectProps) => {
  const types = Object.values(PerformanceReviewTargetNumericCategory)
    .map((value) => ({
      label: KPICategoryLabels[value],
      value: value,
    }))
    .sort(
      (a, b) =>
        orderedKPICategories.indexOf(a.value) -
        orderedKPICategories.indexOf(b.value)
    );

  return <Select data={types} {...props} />;
};
