import { Button, ModalFooter, Radio, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { Controller, useForm } from 'react-hook-form';
import { importValidationSuite } from './import.validator';

// TODO!: Implement when API is already available

enum ImportAction {
  CREATE_NEW_EMPLOYEE = 'CREATE_NEW_EMPLOYEE',
  UPDATE_GENERAL_JOB_CONTRACT_INFORMATION = 'UPDATE_GENERAL_JOB_CONTRACT_INFORMATION',
  UPDATE_PERSONAL_INFORMATION = 'UPDATE_PERSONAL_INFORMATION',
}

export interface EmployeeListImportFormData {
  action: ImportAction;
  link: string;
}

interface Props {
  onClose: () => void;
}

export const EmployeeListImportModalForm = ({ onClose }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<EmployeeListImportFormData>({
    resolver: vestResolver(importValidationSuite),
    defaultValues: {
      action: ImportAction.CREATE_NEW_EMPLOYEE,
      link: '',
    },
  });

  const handleUpload = (data: EmployeeListImportFormData) => {
    console.log('handleUpload', data);
    onClose();
  };

  const action = watch('action');

  return (
    <form
      noValidate
      className='flex flex-col space-y-6'
      onSubmit={handleSubmit(handleUpload)}
    >
      <span>Import employees in bulk via Google spreadsheet.</span>

      <Controller
        control={control}
        name='action'
        render={({ field: { ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              className='flex flex-col space-y-4'
              error={errors.action?.message}
            >
              <Radio
                label='Create new employees'
                value={ImportAction.CREATE_NEW_EMPLOYEE}
              />
              <Radio
                label='Update General/Job/Contract information'
                value={ImportAction.UPDATE_GENERAL_JOB_CONTRACT_INFORMATION}
              />
              <Radio
                label='Update Personal information'
                value={ImportAction.UPDATE_PERSONAL_INFORMATION}
              />
            </Radio.Group>
          );
        }}
      />

      <div className='flex flex-col'>
        <span>Please paste the link to your spreadsheet below.</span>
        <span className='text-link'>Need help importing?</span>
      </div>

      <div className='relative'>
        <TextInput
          {...register('link')}
          placeholder={'https://docs.google.com/spreadsheets/d/...'}
          type='text'
          name='link'
          label='Google Drive Link'
          error={errors.link?.message}
        />

        {action === ImportAction.CREATE_NEW_EMPLOYEE && (
          <span className='absolute right-0 top-0 text-4xs text-link'>
            Spreadsheet Template
          </span>
        )}
      </div>

      <ModalFooter>
        <Button variant='default' onClick={onClose}>
          Cancel
        </Button>
        <Button className='bg-blue-6' type='submit'>
          Upload
        </Button>
      </ModalFooter>
    </form>
  );
};
