import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListPerformanceReviewsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.ListPerformanceReviewFilterInput;
}>;


export type ListPerformanceReviewsQuery = { __typename?: 'Query', listPerformanceReviews: { __typename?: 'ListPerformanceReviews', totalCount: number, items: Array<{ __typename?: 'PerformanceReview', id: string, status: Types.PerformanceReviewStatus, totalTargetWeight: number, employee: { __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string }, currentContract: { __typename?: 'EmployeeContract', contractType: Types.ContractType, company: { __typename?: 'Company', name: string } } | null, probationReview: { __typename?: 'ProbationReview', stages: Array<{ __typename?: 'ProbationReviewStage', deadline: any }> } | null, currentPosition: { __typename?: 'EmployeeJobHistory', level: Types.JobHistoryLevel, jobTitle: string, location: { __typename?: 'Location', name: string }, businessUnit: { __typename?: 'BusinessUnit', name: string }, department: { __typename?: 'Department', name: string } } | null } }> } };


export const ListPerformanceReviewsDocument = gql`
    query ListPerformanceReviews($pageNumber: Int!, $pageSize: Int!, $filter: ListPerformanceReviewFilterInput!) {
  listPerformanceReviews(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
  ) {
    totalCount
    items {
      id
      employee {
        id
        generalInformation {
          preferredFullName
        }
        currentContract {
          contractType
        }
        probationReview {
          stages {
            deadline
          }
        }
        currentContract {
          company {
            name
          }
        }
        currentPosition {
          level
          location {
            name
          }
          businessUnit {
            name
          }
          department {
            name
          }
          jobTitle
        }
      }
      status
      totalTargetWeight
    }
  }
}
    `;

/**
 * __useListPerformanceReviewsQuery__
 *
 * To run a query within a React component, call `useListPerformanceReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPerformanceReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPerformanceReviewsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPerformanceReviewsQuery(baseOptions: Apollo.QueryHookOptions<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>(ListPerformanceReviewsDocument, options);
      }
export function useListPerformanceReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>(ListPerformanceReviewsDocument, options);
        }
export function useListPerformanceReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>(ListPerformanceReviewsDocument, options);
        }
export type ListPerformanceReviewsQueryHookResult = ReturnType<typeof useListPerformanceReviewsQuery>;
export type ListPerformanceReviewsLazyQueryHookResult = ReturnType<typeof useListPerformanceReviewsLazyQuery>;
export type ListPerformanceReviewsSuspenseQueryHookResult = ReturnType<typeof useListPerformanceReviewsSuspenseQuery>;
export type ListPerformanceReviewsQueryResult = Apollo.QueryResult<ListPerformanceReviewsQuery, ListPerformanceReviewsQueryVariables>;