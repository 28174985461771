import { AppLayout, AuthLayout, ProtectedRoute } from '@/shared/components';
import { EmployeeRole } from '@/shared/graphql';
import { AuthUserType } from '@/shared/types';
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { OrganizationChart } from 'src/pages/employees';
import {
  DemographicReport,
  EmployeeAdd,
  EmployeeEdit,
  EmployeeExportCallback,
  EmployeeList,
  GeneralList,
  Login,
  // Logs,
  NotificationSetting,
  NumberOfEmployees,
  PerformanceReviewEdit,
  PerformanceReviewExportCallback,
  PerformanceReviewList,
  PerformanceReviewResultList,
  PerformanceReviewStage,
  ProbationReviewList,
  Setting,
  SignInCallback,
} from '../pages';

export const getRouter = ({ user }: { user: AuthUserType }) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<AppLayout />}>
          <Route
            element={<ProtectedRoute user={user} redirectPath={'/login'} />}
          >
            <Route path='/' element={<Navigate to='/employees' />} />
            <Route path='/employees' element={<EmployeeList />} />
            <Route
              path='/employees/organization-chart'
              element={<OrganizationChart />}
            />
            <Route path='/employees/edit/:id' element={<EmployeeEdit />} />
            <Route path='/employees/add' element={<EmployeeAdd />} />
            <Route
              path='/performance-review'
              element={<PerformanceReviewList />}
            />
            <Route
              path='/performance-review/edit/:id'
              element={<PerformanceReviewEdit />}
            />
            <Route
              path='/performance-review/results'
              element={<PerformanceReviewResultList />}
            />

            <Route path='/probation-review' element={<ProbationReviewList />} />

            <Route
              path='/report/number-of-employees'
              element={<NumberOfEmployees />}
            />
            <Route
              path='/report/demographic-report'
              element={<DemographicReport />}
            />

            <Route
              element={
                <ProtectedRoute
                  user={user}
                  redirectPath={'/'}
                  allowedTypes={[
                    EmployeeRole.SuperAdmin,
                    EmployeeRole.AdminPrivate,
                    EmployeeRole.AdminStandard,
                  ]}
                />
              }
            >
              <Route path='/setting' element={<Setting />} />
              <Route
                path='/setting/performance-review-stage'
                element={<PerformanceReviewStage />}
              />
              <Route
                path='/setting/notification'
                element={<NotificationSetting />}
              />
              <Route path='/setting/general' element={<GeneralList />} />
              {/*<Route path='/setting/logs' element={<Logs />} />*/}
            </Route>
          </Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/auth/callback' element={<SignInCallback />} />
          <Route
            path='/employees/callback'
            element={<EmployeeExportCallback />}
          />
          <Route
            path='/performance-review/callback'
            element={<PerformanceReviewExportCallback />}
          />
        </Route>
      </Route>
    )
  );
};
