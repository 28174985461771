import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DraftProbationReviewForReviewerMutationVariables = Types.Exact<{
  input: Types.DraftProbationReviewForReviewerInput;
}>;


export type DraftProbationReviewForReviewerMutation = { __typename?: 'Mutation', draftProbationReviewForReviewer: { __typename?: 'ProbationReviewOutput', id: string } };


export const DraftProbationReviewForReviewerDocument = gql`
    mutation DraftProbationReviewForReviewer($input: DraftProbationReviewForReviewerInput!) {
  draftProbationReviewForReviewer(input: $input) {
    id
  }
}
    `;
export type DraftProbationReviewForReviewerMutationFn = Apollo.MutationFunction<DraftProbationReviewForReviewerMutation, DraftProbationReviewForReviewerMutationVariables>;

/**
 * __useDraftProbationReviewForReviewerMutation__
 *
 * To run a mutation, you first call `useDraftProbationReviewForReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftProbationReviewForReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftProbationReviewForReviewerMutation, { data, loading, error }] = useDraftProbationReviewForReviewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftProbationReviewForReviewerMutation(baseOptions?: Apollo.MutationHookOptions<DraftProbationReviewForReviewerMutation, DraftProbationReviewForReviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DraftProbationReviewForReviewerMutation, DraftProbationReviewForReviewerMutationVariables>(DraftProbationReviewForReviewerDocument, options);
      }
export type DraftProbationReviewForReviewerMutationHookResult = ReturnType<typeof useDraftProbationReviewForReviewerMutation>;
export type DraftProbationReviewForReviewerMutationResult = Apollo.MutationResult<DraftProbationReviewForReviewerMutation>;
export type DraftProbationReviewForReviewerMutationOptions = Apollo.BaseMutationOptions<DraftProbationReviewForReviewerMutation, DraftProbationReviewForReviewerMutationVariables>;