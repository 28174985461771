import { ProbationReviewDetailType } from '@/shared/types';
import { NumberUtils } from '@/shared/utils';
import { Accordion } from '@any-ui-react/core';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AddNewProbationReview } from '../add-new-probation-review';
import { ProbationReviewStageFields } from './ProbationReviewStageFields';

interface Props {
  isSubmitted: boolean;
}

export const ProbationReviewStages = ({ isSubmitted }: Props) => {
  const { control } = useFormContext<ProbationReviewDetailType>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'stages',
  });

  const onAddProbationReview = (date: Date) => {
    append({
      deadline: format(date, 'yyyy-MM-dd'),
      reviewerId: '',
      reviewerFeedback: '',
      deletable: true,
      selfFeedback: '',
      reviewerName: '',
    });
  };

  const defaultExpandedIds = useMemo(() => {
    const now = new Date();
    const past = fields.filter((field) => new Date(field.deadline) < now);
    return (
      past
        .map((_, index) => `stage.${index}`)
        // Append the next stage to the list of expanded stages
        .concat(`stage.${past.length}`)
    );
  }, [fields]);

  if (!fields.length) {
    return null;
  }

  return (
    <div className='flex flex-col'>
      <div className='my-8 flex w-full justify-between'>
        <div className='text-lg font-semibold'>Probation Review</div>
        {!isSubmitted && (
          <AddNewProbationReview
            onAddProbationReview={onAddProbationReview}
            stages={fields}
          />
        )}
      </div>

      <Accordion
        multiple
        variant='separated'
        defaultValue={defaultExpandedIds}
        classNames={{
          control: 'bg-white px-6 py-2',
          label: 'font-semibold text-lg',
        }}
      >
        {fields.map((field, index) => {
          return (
            <Accordion.Item
              value={`stage.${index}`}
              key={`stage.${index}-${field.deadline}`}
            >
              <Accordion.Control>
                {NumberUtils.ordinal(index + 1)} Review
              </Accordion.Control>
              <Accordion.Panel className='px-2'>
                <ProbationReviewStageFields
                  index={index}
                  onRemove={() => remove(index)}
                  deletable={field.deletable}
                  isSubmitted={isSubmitted}
                  reviewerName={field.reviewerName}
                />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};
