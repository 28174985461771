import {
  BusinessUnitLazySelect,
  DatePicker,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  JobHistoryTypeSelect,
  JobLazySelect,
  JobLevelSelect,
  LocationLazySelect,
} from '@/shared/components';
import { JobHistoryLevel, JobHistoryType } from '@/shared/graphql';
import {
  EmployeeJobHistory,
  EmployeeJobHistoryFormDataType,
  EmployeeJobHistoryType,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Button, Checkbox, ModalFooter, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { jobHistoryFormValidationSuite } from './job-history-form.validator';

export interface JobHistoryFormProps {
  onSuccess: (data: EmployeeJobHistoryType) => void;
  onCancel: () => void;
  jobHistory?: EmployeeJobHistoryType;
  latestJobHistory?: EmployeeJobHistoryType;
  formTitle: string;
  employmentEndDate: string | null;
}

export const JobHistoryForm = ({
  onSuccess,
  onCancel,
  jobHistory,
  latestJobHistory,
  formTitle,
  employmentEndDate,
}: JobHistoryFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    clearErrors,
    watch,
    control,
    reset,
  } = useForm<EmployeeJobHistoryFormDataType & { employmentEndDate: string }>({
    defaultValues: {
      ...jobHistory,
      employmentEndDate: employmentEndDate || '',
    },
    resolver: vestResolver(jobHistoryFormValidationSuite),
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmployeeJobHistory.parse({ ...formValues, id: jobHistory?.id })
        );
      }),
    [jobHistory?.id, handleSubmit, onSuccess]
  );

  const [effectiveDateStart, effectiveDateEnd, type, regionalRole] = watch([
    'effectiveDateStart',
    'effectiveDateEnd',
    'type',
    'regionalRole',
  ]);

  return (
    <>
      <div className='mb-5 flex'>
        <h1 className='self-center text-lg font-semibold'>{formTitle}</h1>
        <Button
          variant='default'
          className='ml-auto'
          onClick={() => {
            if (!latestJobHistory) return;
            reset({
              ...latestJobHistory,
            });
          }}
        >
          Copy Latest History
        </Button>
      </div>
      <form noValidate>
        <fieldset className='form-fieldset w-full'>
          <JobHistoryTypeSelect
            {...register('type')}
            className='col-span-2'
            error={errors.type?.message}
            required
            label={'Type'}
            placeholder={'Enter type'}
            onChange={(value) => {
              setValue('type', value as JobHistoryType);
              clearErrors('type');
            }}
            value={type}
          />

          <div className='z-10'>
            <DatePicker
              error={errors.effectiveDateStart?.message}
              label='Start Date'
              required
              placeholder='Effective Date Start'
              value={effectiveDateStart || undefined}
              onChange={(value) => {
                setValue(
                  'effectiveDateStart',
                  DateUtils.formatDate(
                    new Date(value || '').toISOString(),
                    'yyyy-MM-dd'
                  ),
                  {
                    shouldDirty: true,
                  }
                );
                clearErrors('effectiveDateStart');
              }}
            />
          </div>

          <DatePicker
            error={errors.effectiveDateEnd?.message}
            label='End Date'
            placeholder='Effective Date End'
            value={effectiveDateEnd || undefined}
            onChange={(value) => {
              setValue(
                'effectiveDateEnd',
                value ? DateUtils.formatDate(
                  new Date(value).toISOString(),
                  'yyyy-MM-dd'
                ) : null,
                {
                  shouldDirty: true,
                }
              );
              clearErrors('effectiveDateEnd');
            }}
            clearable={true}
          />

          <Controller
            control={control}
            name='level'
            render={({ field: { value } }) => (
              <>
                <JobLevelSelect
                  isMulti={false}
                  error={errors.level?.message}
                  label='Level'
                  required
                  onChange={(newValue) => {
                    setValue(
                      'level',
                      (newValue as unknown as { value: JobHistoryLevel })
                        .value ?? null,
                      {
                        shouldDirty: true,
                      }
                    );
                  }}
                  value={value}
                />
              </>
            )}
          />

          <Controller
            control={control}
            name='locationId'
            render={({ field: { ...field } }) => (
              <>
                <LocationLazySelect
                  isMulti={false}
                  label={'Office Location'}
                  required
                  error={errors.locationId?.message}
                  valueFormatter={(item) => item.id}
                  className='select-has-min-w-menu'
                  defaultQueryOptions={{
                    variables: {
                      pageNumber: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                      keyword: null,
                      enabled: null,
                    },
                  }}
                  placeholder={'Select Location'}
                  {...field}
                  onChange={(option) => {
                    setValue('locationId', option?.value as string, {
                      shouldDirty: true,
                    });
                  }}
                />
              </>
            )}
          />

          <Controller
            control={control}
            name='businessUnitId'
            render={({ field: { ...field } }) => (
              <>
                <BusinessUnitLazySelect
                  isMulti={false}
                  label={'Business Unit'}
                  required
                  error={errors.businessUnitId?.message}
                  valueFormatter={(item) => item.id}
                  className='select-has-min-w-menu'
                  defaultQueryOptions={{
                    variables: {
                      pageNumber: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                      keyword: null,
                      enabled: null,
                    },
                  }}
                  placeholder={'Business Unit'}
                  {...field}
                  onChange={(option) => {
                    setValue('businessUnitId', option?.value as string, {
                      shouldDirty: true,
                    });
                  }}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name='departmentId'
            render={({ field: { ...field } }) => (
              <>
                <DepartmentLazySelect
                  isMulti={false}
                  required
                  label={'Department'}
                  error={errors.departmentId?.message}
                  valueFormatter={(item) => item.id}
                  className='select-has-min-w-menu'
                  defaultQueryOptions={{
                    variables: {
                      pageNumber: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                      enabled: null,
                    },
                  }}
                  placeholder={'Department'}
                  {...field}
                  onChange={(option) => {
                    setValue('departmentId', option?.value as string, {
                      shouldDirty: true,
                    });
                  }}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name='jobId'
            render={({ field: { ...field } }) => (
              <>
                <JobLazySelect
                  isMulti={false}
                  label={'Job'}
                  required
                  error={errors.jobId?.message}
                  valueFormatter={(item) => item.id}
                  className='select-has-min-w-menu'
                  defaultQueryOptions={{
                    variables: {
                      pageNumber: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                      enabled: null,
                    },
                  }}
                  placeholder={'Job'}
                  {...field}
                  onChange={(option) => {
                    setValue('jobId', option?.value as string, {
                      shouldDirty: true,
                    });
                  }}
                />
              </>
            )}
          />
          <TextInput
            {...register('jobTitle')}
            error={errors.jobTitle?.message}
            required
            label='Job Title'
            placeholder='Job Title'
          />
          <TextInput
            {...register('team')}
            error={errors.team?.message}
            label='Team'
            placeholder='Team'
          />

          <div className='col-span-2'>
            <Checkbox
              {...register('regionalRole')}
              error={errors.regionalRole?.message}
              checked={regionalRole}
              label='Regional Role (Check this box if this person’s cost is allocated to another entity)'
              onChange={(e) => {
                setValue('regionalRole', e.currentTarget.checked, {
                  shouldDirty: true,
                });
              }}
            />
          </div>
        </fieldset>
        <ModalFooter>
          <Button variant='default' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className='bg-blue-6'
            type='submit'
            loading={isSubmitting}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};
