import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListGoogleOrgUnitPathsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListGoogleOrgUnitPathsQuery = { __typename?: 'Query', listGoogleOrgUnitPaths: Array<string> };


export const ListGoogleOrgUnitPathsDocument = gql`
    query listGoogleOrgUnitPaths {
  listGoogleOrgUnitPaths
}
    `;

/**
 * __useListGoogleOrgUnitPathsQuery__
 *
 * To run a query within a React component, call `useListGoogleOrgUnitPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGoogleOrgUnitPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGoogleOrgUnitPathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGoogleOrgUnitPathsQuery(baseOptions?: Apollo.QueryHookOptions<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>(ListGoogleOrgUnitPathsDocument, options);
      }
export function useListGoogleOrgUnitPathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>(ListGoogleOrgUnitPathsDocument, options);
        }
export function useListGoogleOrgUnitPathsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>(ListGoogleOrgUnitPathsDocument, options);
        }
export type ListGoogleOrgUnitPathsQueryHookResult = ReturnType<typeof useListGoogleOrgUnitPathsQuery>;
export type ListGoogleOrgUnitPathsLazyQueryHookResult = ReturnType<typeof useListGoogleOrgUnitPathsLazyQuery>;
export type ListGoogleOrgUnitPathsSuspenseQueryHookResult = ReturnType<typeof useListGoogleOrgUnitPathsSuspenseQuery>;
export type ListGoogleOrgUnitPathsQueryResult = Apollo.QueryResult<ListGoogleOrgUnitPathsQuery, ListGoogleOrgUnitPathsQueryVariables>;