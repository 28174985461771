import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import {
  PerformanceReviewStageListFilter,
  usePerformanceReviewStageListFilter,
} from './components';
import { PerformanceReviewStageListTable } from './components/performance-review-list-table';
import { useListPerformanceReviewCompanyStatusQuery } from './graphql';

export const PerformanceReviewStage = () => {
  const { filters } = usePerformanceReviewStageListFilter();
  const [, scrollTo] = useWindowScroll();

  const { data, loading } = useListPerformanceReviewCompanyStatusQuery({
    variables: {
      period: {
        year: filters.current.year,
        quarter: filters.current.quarter,
      },
    },
  });

  return (
    <>
      <PageTitle text='Performance Review Stage' />
      <Card withPadding={false}>
        <PerformanceReviewStageListFilter filters={filters} />
        <PerformanceReviewStageListTable
          filters={filters}
          data={data?.listPerformanceReviewCompanyStatus || []}
          loading={loading}
          page={1}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={1}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        count={data?.listPerformanceReviewCompanyStatus.length || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
