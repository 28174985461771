import classNames from 'classnames';

interface Props {
  selected: boolean;
  onClick: () => void;
}

export const PerformanceReviewStatusRadioButton = ({
  onClick,
  selected,
}: Props) => {
  return (
    <div
      className='group relative flex flex-col items-center hover:cursor-pointer'
      onClick={onClick}
    >
      <div
        className={classNames('size-4 rounded-full border', {
          'border-white bg-blue-500 p-2 shadow-md ': selected,
          'border-gray-2 bg-gray-1 ': !selected,
        })}
      />
      <div
        className={classNames(
          'absolute left-1/2 top-1/2 size-2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white ',
          {
            'hidden group-hover:block group-hover:bg-white/30': !selected,
          }
        )}
      />
    </div>
  );
};
