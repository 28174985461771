import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyGoogleOfflineTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type VerifyGoogleOfflineTokenMutation = { __typename?: 'Mutation', verifyGoogleOfflineToken: boolean };


export const VerifyGoogleOfflineTokenDocument = gql`
    mutation verifyGoogleOfflineToken {
  verifyGoogleOfflineToken
}
    `;
export type VerifyGoogleOfflineTokenMutationFn = Apollo.MutationFunction<VerifyGoogleOfflineTokenMutation, VerifyGoogleOfflineTokenMutationVariables>;

/**
 * __useVerifyGoogleOfflineTokenMutation__
 *
 * To run a mutation, you first call `useVerifyGoogleOfflineTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyGoogleOfflineTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyGoogleOfflineTokenMutation, { data, loading, error }] = useVerifyGoogleOfflineTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyGoogleOfflineTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyGoogleOfflineTokenMutation, VerifyGoogleOfflineTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyGoogleOfflineTokenMutation, VerifyGoogleOfflineTokenMutationVariables>(VerifyGoogleOfflineTokenDocument, options);
      }
export type VerifyGoogleOfflineTokenMutationHookResult = ReturnType<typeof useVerifyGoogleOfflineTokenMutation>;
export type VerifyGoogleOfflineTokenMutationResult = Apollo.MutationResult<VerifyGoogleOfflineTokenMutation>;
export type VerifyGoogleOfflineTokenMutationOptions = Apollo.BaseMutationOptions<VerifyGoogleOfflineTokenMutation, VerifyGoogleOfflineTokenMutationVariables>;