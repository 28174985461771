import { useFilters } from '@/shared/hooks';
import { DateUtils } from '@/shared/utils';
import {
  PerformanceReviewListFilterType,
  PerformanceReviewListParser,
} from './PerformanceReviewList.filters';

export const usePerformanceReviewListFilter = () => {
  const filters = useFilters<PerformanceReviewListFilterType>(
    {
      year: new Date().getFullYear(),
      quarter: DateUtils.getCurrentPerformanceReviewQuarter(),
      keyword: '',
      status: [],
      contractTypes: [],
      companyIds: [],
      locationIds: [],
      businessUnitIds: [],
      departmentIds: [],
      levels: [],
      showDirectReportsOnly: false,
      page: 1,
    },
    {
      parser: PerformanceReviewListParser.parse,
    }
  );

  return { filters };
};
