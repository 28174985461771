import { z } from 'zod';
import { EmployeeContractHistory } from './employee-contract-history';
import { EmployeeExternalAccount } from './employee-external-account';
import { EmployeeGeneralInformation } from './employee-general-information';
import { EmployeeJobHistory } from './employee-job-history';
import { EmployeeOffBoarding } from './employee-off-boarding';
import { EmployeePermission } from './employee-permission';
import { EmployeePersonalInformation } from './employee-personal-information';
import { PerformanceReviewDetail } from './performance-review';
import { ProbationReviewDetail } from './probation-review-detail';

export const EmployeeDetail = z.object({
  id: z.string().default(''),
  generalInformation: EmployeeGeneralInformation.default(
    EmployeeGeneralInformation.parse({})
  ),
  personalInformation: EmployeePersonalInformation.default(
    EmployeePersonalInformation.parse({})
  ),
  permission: EmployeePermission.default(EmployeePermission.parse({})),
  offBoarding: EmployeeOffBoarding.default(EmployeeOffBoarding.parse({})),
  jobHistories: z.array(EmployeeJobHistory).default([]),
  contractHistories: z.array(EmployeeContractHistory).default([]),
  contractNotes: z.string().nullable().default(''),
  jobHistoryNotes: z.string().nullable().default(''),
  externalAccounts: z.array(EmployeeExternalAccount).default([]),
  probationReview: ProbationReviewDetail.default(
    ProbationReviewDetail.parse({})
  ),
  performanceReviews: z.array(PerformanceReviewDetail).default([]),
  managers: z
    .array(
      z.object({
        id: z.string().default(''),
        name: z.string().default(''),
      })
    )
    .default([]),
});
export type EmployeeDetailType = z.infer<typeof EmployeeDetail>;
