import { create, enforce, test } from 'vest';
import { EmployeeListImportFormData } from './EmployeeListImportModalForm';

export const importValidationSuite = create(
  ({ link, action }: EmployeeListImportFormData) => {
    test('link', 'Link is required', () => {
      enforce(link).isNotEmpty();
    });

    test('link', 'Link format is invalid', () => {
      enforce(link).isURL();
    });

    test('action', 'Action is required', () => {
      enforce(action).isNotEmpty();
    });
  }
);
