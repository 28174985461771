import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewPerformanceTargetsMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewPerformanceTargetsInput;
}>;


export type UpdatePerformanceReviewPerformanceTargetsMutation = { __typename?: 'Mutation', updatePerformanceReviewPerformanceTargets: { __typename?: 'PerformanceReviewOutput', id: string } };


export const UpdatePerformanceReviewPerformanceTargetsDocument = gql`
    mutation UpdatePerformanceReviewPerformanceTargets($input: UpdatePerformanceReviewPerformanceTargetsInput!) {
  updatePerformanceReviewPerformanceTargets(input: $input) {
    id
  }
}
    `;
export type UpdatePerformanceReviewPerformanceTargetsMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewPerformanceTargetsMutation, UpdatePerformanceReviewPerformanceTargetsMutationVariables>;

/**
 * __useUpdatePerformanceReviewPerformanceTargetsMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewPerformanceTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewPerformanceTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewPerformanceTargetsMutation, { data, loading, error }] = useUpdatePerformanceReviewPerformanceTargetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewPerformanceTargetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewPerformanceTargetsMutation, UpdatePerformanceReviewPerformanceTargetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewPerformanceTargetsMutation, UpdatePerformanceReviewPerformanceTargetsMutationVariables>(UpdatePerformanceReviewPerformanceTargetsDocument, options);
      }
export type UpdatePerformanceReviewPerformanceTargetsMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewPerformanceTargetsMutation>;
export type UpdatePerformanceReviewPerformanceTargetsMutationResult = Apollo.MutationResult<UpdatePerformanceReviewPerformanceTargetsMutation>;
export type UpdatePerformanceReviewPerformanceTargetsMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewPerformanceTargetsMutation, UpdatePerformanceReviewPerformanceTargetsMutationVariables>;