import {
  ExternalLinkIcon,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { DateUtils } from '@/shared/utils';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PerformanceReviewStatusBadge } from 'src/pages/performance-review/components';
import { PerformanceReviewDetailType } from 'src/types/performance-review';

export const PerformanceReview = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  const layout: TableLayout<PerformanceReviewDetailType>[] = useMemo(
    () => [
      {
        headerRender: () => 'Year',
        accessor: 'year',
        minWidth: '10rem',
        cellRender: ({ year }) => year,
      },
      {
        headerRender: () => 'Quarter',
        accessor: 'quarter',
        cellRender: ({ quarter }) => quarter.replace('_', '/'),
      },
      {
        headerRender: () => 'Effective Period',
        accessor: 'effectivePeriod',
        cellRender: ({ effectivePeriod }) => {
          return (
            <span>
              {DateUtils.formatDate(effectivePeriod.start, 'MMM dd, yyyy')}
              {' - '}
              {DateUtils.formatDate(effectivePeriod.end, 'MMM dd, yyyy')}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Status',
        accessor: 'status',
        cellRender: ({ status }) => {
          return <PerformanceReviewStatusBadge status={status} />;
        },
      },
      {
        headerRender: () => 'Link to form',
        accessor: 'link',
        cellRender: ({ id }) => {
          return (
            <Link
              to={`/performance-review/edit/${id}`}
              className='mx-8 flex text-link'
            >
              <ExternalLinkIcon className='size-4 ' />
            </Link>
          );
        },
      },
    ],
    []
  );

  return (
    <Table<PerformanceReviewDetailType>
      page={1}
      allowFloatingHeader
      layout={layout}
      data={employeeDetail.performanceReviews || []}
      notFoundTemplate={
        <NoResult
          title={'No data found'}
          titleClassName='text-sm text-gray-9'
          wrapperClassName='py-10'
        />
      }
    />
  );
};
