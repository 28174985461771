import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerformanceReviewTargetNumericMonthlyMutationVariables = Types.Exact<{
  input: Types.CreatePerformanceReviewTargetNumericMonthlyInput;
}>;


export type CreatePerformanceReviewTargetNumericMonthlyMutation = { __typename?: 'Mutation', createPerformanceReviewTargetNumericMonthly: boolean };


export const CreatePerformanceReviewTargetNumericMonthlyDocument = gql`
    mutation CreatePerformanceReviewTargetNumericMonthly($input: CreatePerformanceReviewTargetNumericMonthlyInput!) {
  createPerformanceReviewTargetNumericMonthly(input: $input)
}
    `;
export type CreatePerformanceReviewTargetNumericMonthlyMutationFn = Apollo.MutationFunction<CreatePerformanceReviewTargetNumericMonthlyMutation, CreatePerformanceReviewTargetNumericMonthlyMutationVariables>;

/**
 * __useCreatePerformanceReviewTargetNumericMonthlyMutation__
 *
 * To run a mutation, you first call `useCreatePerformanceReviewTargetNumericMonthlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerformanceReviewTargetNumericMonthlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerformanceReviewTargetNumericMonthlyMutation, { data, loading, error }] = useCreatePerformanceReviewTargetNumericMonthlyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerformanceReviewTargetNumericMonthlyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerformanceReviewTargetNumericMonthlyMutation, CreatePerformanceReviewTargetNumericMonthlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerformanceReviewTargetNumericMonthlyMutation, CreatePerformanceReviewTargetNumericMonthlyMutationVariables>(CreatePerformanceReviewTargetNumericMonthlyDocument, options);
      }
export type CreatePerformanceReviewTargetNumericMonthlyMutationHookResult = ReturnType<typeof useCreatePerformanceReviewTargetNumericMonthlyMutation>;
export type CreatePerformanceReviewTargetNumericMonthlyMutationResult = Apollo.MutationResult<CreatePerformanceReviewTargetNumericMonthlyMutation>;
export type CreatePerformanceReviewTargetNumericMonthlyMutationOptions = Apollo.BaseMutationOptions<CreatePerformanceReviewTargetNumericMonthlyMutation, CreatePerformanceReviewTargetNumericMonthlyMutationVariables>;