import { PageTitle } from '@/shared/components';
import {
  useReportDemographicQuery,
  useReportNumberOfEmployeeAllQuery,
} from '@/shared/graphql';
import { useFilters } from '@/shared/hooks';
import {
  DemographicReportFilter as DemographicReportFilterParser,
  DemographicReportFilterType,
} from '@/shared/types';
import { Card } from '@any-ui-react/core';
import { format } from 'date-fns';
import {
  DemographicReportDetails,
  DemographicReportFilters,
} from './components';

export const DemographicReport = () => {
  const filters = useFilters<DemographicReportFilterType>(
    {
      yearMonth: format(new Date(), 'yyyy-MM'),
      contractTypes: [],
      countryIds: [],
      locationIds: [],
      companyIds: [],
      businessUnitIds: [],
      departmentIds: [],
      jobIds: [],
      nationalityIds: [],
    },
    {
      parser: DemographicReportFilterParser.parse,
    }
  );

  const { data, loading } = useReportDemographicQuery({
    variables: {
      yearMonth: filters.current.yearMonth,
      filter: {
        contractTypes: filters.current.contractTypes,
        countryIds: filters.current.countryIds,
        locationIds: filters.current.locationIds,
        companyIds: filters.current.companyIds,
        businessUnitIds: filters.current.businessUnitIds,
        departmentIds: filters.current.departmentIds,
        jobIds: filters.current.jobIds,
        nationalityIds: filters.current.nationalityIds,
      },
    },
  });

  const { data: reportEmployee } = useReportNumberOfEmployeeAllQuery({
    variables: {
      year: filters.current.yearMonth.split('-')[0],
      contractTypes: filters.current.contractTypes,
    },
  });

  return (
    <>
      <PageTitle text='Demographic Report' />
      <Card>
        <DemographicReportFilters filters={filters} />
        <DemographicReportDetails
          data={data?.reportDemographic}
          loading={loading}
          turnoverRate={
            reportEmployee?.reportNumberOfEmployeeAll.subtotal.turnOverRate
          }
          year={filters.current.yearMonth.split('-')[0]}
        />
      </Card>
    </>
  );
};
