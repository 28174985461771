import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DraftProbationReviewForRevieweeMutationVariables = Types.Exact<{
  input: Types.DraftProbationReviewForRevieweeInput;
}>;


export type DraftProbationReviewForRevieweeMutation = { __typename?: 'Mutation', draftProbationReviewForReviewee: { __typename?: 'ProbationReviewOutput', id: string } };


export const DraftProbationReviewForRevieweeDocument = gql`
    mutation DraftProbationReviewForReviewee($input: DraftProbationReviewForRevieweeInput!) {
  draftProbationReviewForReviewee(input: $input) {
    id
  }
}
    `;
export type DraftProbationReviewForRevieweeMutationFn = Apollo.MutationFunction<DraftProbationReviewForRevieweeMutation, DraftProbationReviewForRevieweeMutationVariables>;

/**
 * __useDraftProbationReviewForRevieweeMutation__
 *
 * To run a mutation, you first call `useDraftProbationReviewForRevieweeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftProbationReviewForRevieweeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftProbationReviewForRevieweeMutation, { data, loading, error }] = useDraftProbationReviewForRevieweeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftProbationReviewForRevieweeMutation(baseOptions?: Apollo.MutationHookOptions<DraftProbationReviewForRevieweeMutation, DraftProbationReviewForRevieweeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DraftProbationReviewForRevieweeMutation, DraftProbationReviewForRevieweeMutationVariables>(DraftProbationReviewForRevieweeDocument, options);
      }
export type DraftProbationReviewForRevieweeMutationHookResult = ReturnType<typeof useDraftProbationReviewForRevieweeMutation>;
export type DraftProbationReviewForRevieweeMutationResult = Apollo.MutationResult<DraftProbationReviewForRevieweeMutation>;
export type DraftProbationReviewForRevieweeMutationOptions = Apollo.BaseMutationOptions<DraftProbationReviewForRevieweeMutation, DraftProbationReviewForRevieweeMutationVariables>;