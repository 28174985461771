import classNames from 'classnames';

export const CardTitle = ({
  children,
  className,
  withMargin = true,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  withMargin?: boolean;
}) => (
  <div
    className={classNames(
      'text-lg font-semibold',
      { 'mb-4 lg:mb-6': withMargin },
      className
    )}
  >
    {children}
  </div>
);
