import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubmitProbationReviewMutationVariables = Types.Exact<{
  input: Types.SubmitProbationReviewInput;
}>;


export type SubmitProbationReviewMutation = { __typename?: 'Mutation', submitProbationReview: { __typename?: 'ProbationReviewOutput', id: string } };


export const SubmitProbationReviewDocument = gql`
    mutation SubmitProbationReview($input: SubmitProbationReviewInput!) {
  submitProbationReview(input: $input) {
    id
  }
}
    `;
export type SubmitProbationReviewMutationFn = Apollo.MutationFunction<SubmitProbationReviewMutation, SubmitProbationReviewMutationVariables>;

/**
 * __useSubmitProbationReviewMutation__
 *
 * To run a mutation, you first call `useSubmitProbationReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProbationReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProbationReviewMutation, { data, loading, error }] = useSubmitProbationReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitProbationReviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitProbationReviewMutation, SubmitProbationReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitProbationReviewMutation, SubmitProbationReviewMutationVariables>(SubmitProbationReviewDocument, options);
      }
export type SubmitProbationReviewMutationHookResult = ReturnType<typeof useSubmitProbationReviewMutation>;
export type SubmitProbationReviewMutationResult = Apollo.MutationResult<SubmitProbationReviewMutation>;
export type SubmitProbationReviewMutationOptions = Apollo.BaseMutationOptions<SubmitProbationReviewMutation, SubmitProbationReviewMutationVariables>;