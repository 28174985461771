import {
  ContractType,
  JobHistoryLevel,
  PerformanceReviewQuarter,
} from '@/shared/graphql';
import { DateUtils } from 'src/utils/date.utils';
import { z } from 'zod';

export const PerformanceReviewResultListParser = z.object({
  year: z.number().default(new Date().getFullYear()),
  quarter: z
    .nativeEnum(PerformanceReviewQuarter)
    .catch(DateUtils.getCurrentPerformanceReviewQuarter()),
  locationIds: z.array(z.string()).optional().catch([]),
  levels: z.array(z.nativeEnum(JobHistoryLevel)).optional().catch([]),
  keyword: z.string().optional().nullish().catch(undefined),
  departmentIds: z.array(z.string()).optional().catch([]),
  contractTypes: z.array(z.nativeEnum(ContractType)).optional().catch([]),
  companyIds: z.array(z.string()).optional().catch([]),
  businessUnitIds: z.array(z.string()).optional().catch([]),
  page: z.number().catch(1),
});

export type PerformanceReviewResultListFilterType = z.infer<
  typeof PerformanceReviewResultListParser
>;
