import { z } from 'zod';

export enum PerformanceReviewTab {
  OVERVIEW = 'overview',
  GROWTH_TARGET = 'growth-target',
  VALUE = 'value',
  PERFORMANCE_TARGET = 'performance-target',
  SETTING = 'setting',
}

export const PerformanceReviewTabLabels = {
  [PerformanceReviewTab.OVERVIEW]: 'Overview',
  [PerformanceReviewTab.VALUE]: 'Value',
  [PerformanceReviewTab.GROWTH_TARGET]: 'Growth Target',
  [PerformanceReviewTab.PERFORMANCE_TARGET]: 'Performance Target',
  [PerformanceReviewTab.SETTING]: 'Setting',
};

export const PerformanceReviewParser = z.object({
  tab: z
    .nativeEnum(PerformanceReviewTab)
    .optional()
    .default(PerformanceReviewTab.OVERVIEW),
}) satisfies z.ZodType<
  {
    tab?: PerformanceReviewTab;
  },
  z.ZodTypeDef,
  unknown
>;

export type PerformanceReviewFilterType = z.infer<
  typeof PerformanceReviewParser
>;
