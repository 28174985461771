import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListPerformanceReviewCompanyStatusQueryVariables = Types.Exact<{
  period: Types.PerformanceReviewPeriodInput;
}>;


export type ListPerformanceReviewCompanyStatusQuery = { __typename?: 'Query', listPerformanceReviewCompanyStatus: Array<{ __typename?: 'PerformanceReviewCompanyStatus', status: Types.PerformanceReviewStatus, companyId: string, period: { __typename?: 'PerformanceReviewPeriod', year: number, quarter: Types.PerformanceReviewQuarter }, company: { __typename?: 'Company', name: string } }> };


export const ListPerformanceReviewCompanyStatusDocument = gql`
    query ListPerformanceReviewCompanyStatus($period: PerformanceReviewPeriodInput!) {
  listPerformanceReviewCompanyStatus(period: $period) {
    status
    period {
      year
      quarter
    }
    companyId
    company {
      name
    }
  }
}
    `;

/**
 * __useListPerformanceReviewCompanyStatusQuery__
 *
 * To run a query within a React component, call `useListPerformanceReviewCompanyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPerformanceReviewCompanyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPerformanceReviewCompanyStatusQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useListPerformanceReviewCompanyStatusQuery(baseOptions: Apollo.QueryHookOptions<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>(ListPerformanceReviewCompanyStatusDocument, options);
      }
export function useListPerformanceReviewCompanyStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>(ListPerformanceReviewCompanyStatusDocument, options);
        }
export function useListPerformanceReviewCompanyStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>(ListPerformanceReviewCompanyStatusDocument, options);
        }
export type ListPerformanceReviewCompanyStatusQueryHookResult = ReturnType<typeof useListPerformanceReviewCompanyStatusQuery>;
export type ListPerformanceReviewCompanyStatusLazyQueryHookResult = ReturnType<typeof useListPerformanceReviewCompanyStatusLazyQuery>;
export type ListPerformanceReviewCompanyStatusSuspenseQueryHookResult = ReturnType<typeof useListPerformanceReviewCompanyStatusSuspenseQuery>;
export type ListPerformanceReviewCompanyStatusQueryResult = Apollo.QueryResult<ListPerformanceReviewCompanyStatusQuery, ListPerformanceReviewCompanyStatusQueryVariables>;