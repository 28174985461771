import { useFilters } from '@/shared/hooks';
import {
  EmployeeListFilterType,
  EmployeeListParser,
} from './EmployeeList.filters';

export const useEmployeeListFilter = () => {
  const filters = useFilters<EmployeeListFilterType>(
    {
      id: null,
      name: null,
      page: 1,
      keyword: null,
      statuses: [],
      hiredDate: null,
      contractTypes: [],
      levels: [],
      companyIds: [],
      locationIds: [],
      divisionIds: [],
      businessUnitIds: [],
      managerIds: [],
      departmentIds: [],
      jobIds: [],
      nationalityIds: [],
      roles: [],
      isLeave: false,
      isOnSecondment: false,
      managerIncludesAllConnections: false,
      isOpenFilter: false,
      externalAccount: null,
    },
    {
      parser: EmployeeListParser.parse,
    }
  );

  return { filters };
};
