import {
  ContractType,
  EmployeeStatus,
  JobHistoryLevel,
} from '@/shared/graphql';
import { z } from 'zod';

export const Employee = z.object({
  id: z.string().default(''),
  preferredFullName: z.string().default(''),
  legalFullName: z.string().default(''),
  employeeId: z.string().default(''),
  status: z.nativeEnum(EmployeeStatus).default(EmployeeStatus.Active),
  email: z.string().nullable().default(null),
  hiredDate: z.string().nullable().default(null),
  contractType: z.nativeEnum(ContractType).default(ContractType.FtPermanent),
  officeLocation: z.string().default(''),
  company: z.string().default(''),
  division: z.string().default(''),
  businessUnit: z.string().default(''),
  department: z.string().default(''),
  job: z.string().default(''),
  jobTitle: z.string().default(''),
  level: z.nativeEnum(JobHistoryLevel).default(JobHistoryLevel.L0),
  primaryManager: z.string().default(''),
  dottedLineManager: z.string().default(''),
  nationality: z.string().default(''),
  isGoogleEnabled: z.boolean().default(false),
  isSlackEnabled: z.boolean().default(false),
  isHubspotEnabled: z.boolean().default(false),
  isGreenhouseEnabled: z.boolean().default(false),
  lastWorkingDate: z.string().nullable().default(null),
  terminationDate: z.string().nullable().default(null),
  reasonForLeaving: z.string().nullable().default(null),
});
export type EmployeeType = z.infer<typeof Employee>;
