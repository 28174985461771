import { ApolloError } from '@apollo/client';

export class ApiUtils {
  static getErrorMessage = (error: unknown) => {
    if (error instanceof ApolloError) {
      const code =
        error.graphQLErrors[error.graphQLErrors.length - 1]?.extensions[
          'detail'
        ];

      if (typeof code === 'string') {
        return code;
      }
    }

    return 'An error has occurred.';
  };
}
