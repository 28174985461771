import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerformanceReviewTargetImpactFocusedMutationVariables = Types.Exact<{
  input: Types.CreatePerformanceReviewTargetImpactFocusedInput;
}>;


export type CreatePerformanceReviewTargetImpactFocusedMutation = { __typename?: 'Mutation', createPerformanceReviewTargetImpactFocused: boolean };


export const CreatePerformanceReviewTargetImpactFocusedDocument = gql`
    mutation CreatePerformanceReviewTargetImpactFocused($input: CreatePerformanceReviewTargetImpactFocusedInput!) {
  createPerformanceReviewTargetImpactFocused(input: $input)
}
    `;
export type CreatePerformanceReviewTargetImpactFocusedMutationFn = Apollo.MutationFunction<CreatePerformanceReviewTargetImpactFocusedMutation, CreatePerformanceReviewTargetImpactFocusedMutationVariables>;

/**
 * __useCreatePerformanceReviewTargetImpactFocusedMutation__
 *
 * To run a mutation, you first call `useCreatePerformanceReviewTargetImpactFocusedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerformanceReviewTargetImpactFocusedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerformanceReviewTargetImpactFocusedMutation, { data, loading, error }] = useCreatePerformanceReviewTargetImpactFocusedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerformanceReviewTargetImpactFocusedMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerformanceReviewTargetImpactFocusedMutation, CreatePerformanceReviewTargetImpactFocusedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerformanceReviewTargetImpactFocusedMutation, CreatePerformanceReviewTargetImpactFocusedMutationVariables>(CreatePerformanceReviewTargetImpactFocusedDocument, options);
      }
export type CreatePerformanceReviewTargetImpactFocusedMutationHookResult = ReturnType<typeof useCreatePerformanceReviewTargetImpactFocusedMutation>;
export type CreatePerformanceReviewTargetImpactFocusedMutationResult = Apollo.MutationResult<CreatePerformanceReviewTargetImpactFocusedMutation>;
export type CreatePerformanceReviewTargetImpactFocusedMutationOptions = Apollo.BaseMutationOptions<CreatePerformanceReviewTargetImpactFocusedMutation, CreatePerformanceReviewTargetImpactFocusedMutationVariables>;