import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportPerformanceReviewsQueryVariables = Types.Exact<{
  period: Types.PerformanceReviewPeriodInput;
  employeeIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type ExportPerformanceReviewsQuery = { __typename?: 'Query', exportPerformanceReviews: any };


export const ExportPerformanceReviewsDocument = gql`
    query exportPerformanceReviews($period: PerformanceReviewPeriodInput!, $employeeIds: [ID!]!) {
  exportPerformanceReviews(period: $period, employeeIds: $employeeIds)
}
    `;

/**
 * __useExportPerformanceReviewsQuery__
 *
 * To run a query within a React component, call `useExportPerformanceReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPerformanceReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPerformanceReviewsQuery({
 *   variables: {
 *      period: // value for 'period'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useExportPerformanceReviewsQuery(baseOptions: Apollo.QueryHookOptions<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>(ExportPerformanceReviewsDocument, options);
      }
export function useExportPerformanceReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>(ExportPerformanceReviewsDocument, options);
        }
export function useExportPerformanceReviewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>(ExportPerformanceReviewsDocument, options);
        }
export type ExportPerformanceReviewsQueryHookResult = ReturnType<typeof useExportPerformanceReviewsQuery>;
export type ExportPerformanceReviewsLazyQueryHookResult = ReturnType<typeof useExportPerformanceReviewsLazyQuery>;
export type ExportPerformanceReviewsSuspenseQueryHookResult = ReturnType<typeof useExportPerformanceReviewsSuspenseQuery>;
export type ExportPerformanceReviewsQueryResult = Apollo.QueryResult<ExportPerformanceReviewsQuery, ExportPerformanceReviewsQueryVariables>;