import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListUserEmployeesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.InputMaybe<Types.ListEmployeesFilterInput>;
}>;


export type ListUserEmployeesQuery = { __typename?: 'Query', listEmployees: { __typename?: 'ListEmployees', totalCount: number, items: Array<{ __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string, legalFullName: string }, currentContract: { __typename?: 'EmployeeContract', company: { __typename?: 'Company', name: string } } | null, currentPosition: { __typename?: 'EmployeeJobHistory', businessUnit: { __typename?: 'BusinessUnit', name: string }, department: { __typename?: 'Department', name: string } } | null }> } };


export const ListUserEmployeesDocument = gql`
    query listUserEmployees($pageNumber: Int!, $pageSize: Int!, $filter: ListEmployeesFilterInput) {
  listEmployees(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter) {
    items {
      id
      generalInformation {
        preferredFullName
        legalFullName
      }
      currentContract {
        company {
          name
        }
      }
      currentPosition {
        businessUnit {
          name
        }
        department {
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useListUserEmployeesQuery__
 *
 * To run a query within a React component, call `useListUserEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserEmployeesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListUserEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>(ListUserEmployeesDocument, options);
      }
export function useListUserEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>(ListUserEmployeesDocument, options);
        }
export function useListUserEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>(ListUserEmployeesDocument, options);
        }
export type ListUserEmployeesQueryHookResult = ReturnType<typeof useListUserEmployeesQuery>;
export type ListUserEmployeesLazyQueryHookResult = ReturnType<typeof useListUserEmployeesLazyQuery>;
export type ListUserEmployeesSuspenseQueryHookResult = ReturnType<typeof useListUserEmployeesSuspenseQuery>;
export type ListUserEmployeesQueryResult = Apollo.QueryResult<ListUserEmployeesQuery, ListUserEmployeesQueryVariables>;