import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewTargetImpactFocusedMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewTargetImpactFocusedInput;
}>;


export type UpdatePerformanceReviewTargetImpactFocusedMutation = { __typename?: 'Mutation', updatePerformanceReviewTargetImpactFocused: boolean };


export const UpdatePerformanceReviewTargetImpactFocusedDocument = gql`
    mutation UpdatePerformanceReviewTargetImpactFocused($input: UpdatePerformanceReviewTargetImpactFocusedInput!) {
  updatePerformanceReviewTargetImpactFocused(input: $input)
}
    `;
export type UpdatePerformanceReviewTargetImpactFocusedMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewTargetImpactFocusedMutation, UpdatePerformanceReviewTargetImpactFocusedMutationVariables>;

/**
 * __useUpdatePerformanceReviewTargetImpactFocusedMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewTargetImpactFocusedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewTargetImpactFocusedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewTargetImpactFocusedMutation, { data, loading, error }] = useUpdatePerformanceReviewTargetImpactFocusedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewTargetImpactFocusedMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewTargetImpactFocusedMutation, UpdatePerformanceReviewTargetImpactFocusedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewTargetImpactFocusedMutation, UpdatePerformanceReviewTargetImpactFocusedMutationVariables>(UpdatePerformanceReviewTargetImpactFocusedDocument, options);
      }
export type UpdatePerformanceReviewTargetImpactFocusedMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewTargetImpactFocusedMutation>;
export type UpdatePerformanceReviewTargetImpactFocusedMutationResult = Apollo.MutationResult<UpdatePerformanceReviewTargetImpactFocusedMutation>;
export type UpdatePerformanceReviewTargetImpactFocusedMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewTargetImpactFocusedMutation, UpdatePerformanceReviewTargetImpactFocusedMutationVariables>;