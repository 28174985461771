import { EmployeeDetailType } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Link } from 'react-router-dom';

export const GeneralInformationDetails = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const employeeGeneralInformation = employee.generalInformation;
  return (
    <div>
      <div className='form-fieldset'>
        <div>
          <p className='mb-2 font-semibold'>Preferred Full Name</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.preferredFullName}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Legal Full name</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.legalFullName}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Name in Local Language</p>
          <p className='text-gray-7'>
            {StringUtils.withFallback(
              employeeGeneralInformation.nameInLocalLanguage
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>ﾌﾘｶﾞﾅ (Kana of Japanese)</p>
          <p className='text-gray-7'>
            {StringUtils.withFallback(
              employeeGeneralInformation.japanesePhoneticName
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Nationality</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.nationality}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Primary Manager</p>
          <Link
            className='text-blue-6'
            to={`/employees/edit/${employeeGeneralInformation.primaryManagerId}`}
          >
            {employeeGeneralInformation.primaryManager}
          </Link>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Dotted Line Manager</p>
          {employeeGeneralInformation.dottedLineManagerId ? (
            <Link
              className='text-blue-6'
              to={`/employees/edit/${employeeGeneralInformation.dottedLineManagerId}`}
            >
              {employeeGeneralInformation.dottedLineManager}
            </Link>
          ) : (
            <p>-</p>
          )}
        </div>
        <div>
          <p className='mb-2 font-semibold'>Probation Review Date</p>
          <p className='text-gray-7'>{'XXX'}</p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Company Email</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.emailAddress}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Hiring Source</p>
          <p className='text-gray-7'>
            {StringUtils.capitalizeAll(
              employeeGeneralInformation.hiringSource || '-',
              '_'
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Greenhouse Link</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.greenhouseLink ? (
              <Link
                className='text-blue-6'
                target='_blank'
                to={employeeGeneralInformation.greenhouseLink}
              >
                {employeeGeneralInformation.greenhouseLink}
              </Link>
            ) : (
              '-'
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Hired Date</p>
          <p className='text-gray-7'>
            {DateUtils.formatDate(
              employeeGeneralInformation.hiredDate,
              'yyyy-MM-dd'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
