import { Card } from '@/shared/components';
import { ContractHistory } from './contract-history';
import { JobHistory } from './job-history';

export const JobContractHistory = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  return (
    <Card withPadding={false}>
      <div className=' px-6 pt-6'>
        <JobHistory onFormStateChange={onFormStateChange} />
      </div>
      <hr className='m-6' />
      <div className='px-6 py-6'>
        <ContractHistory onFormStateChange={onFormStateChange} />
      </div>
    </Card>
  );
};
