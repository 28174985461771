import { CurrencyUtils } from '@/shared/utils';
import { NumberInput, NumberInputProps } from '@any-ui-react/core';
import { useUncontrolled } from '@mantine/hooks';
import cx from 'classnames';
import { forwardRef } from 'react';

interface InputPriceProps
  extends Omit<NumberInputProps, 'defaultValue' | 'value' | 'onChange'> {
  currency?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (e: string) => void;
}

export const InputPrice = forwardRef<HTMLInputElement, InputPriceProps>(
  (
    {
      value,
      defaultValue,
      currency = CurrencyUtils.DEFAULT,
      onChange,
      classNames,
      ...rest
    }: InputPriceProps,
    ref
  ) => {
    const [_value, handleChange] = useUncontrolled<string>({
      value,
      defaultValue,
      finalValue: '',
      onChange,
    });

    return (
      <NumberInput
        ref={ref}
        classNames={{
          ...classNames,
          rightSection: cx(classNames?.rightSection, 'w-max'),
        }}
        rightSection={
          <span className='border-l border-gray-2 pl-1 pr-2'>{currency}</span>
        }
        rightSectionWidth={48}
        min={0}
        value={_value ? Number(_value) : ''}
        onChange={(e) => handleChange(e.toString())}
        {...rest}
      />
    );
  }
);
