import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewTargetNumericMonthlyMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewTargetNumericMonthlyInput;
}>;


export type UpdatePerformanceReviewTargetNumericMonthlyMutation = { __typename?: 'Mutation', updatePerformanceReviewTargetNumericMonthly: boolean };


export const UpdatePerformanceReviewTargetNumericMonthlyDocument = gql`
    mutation UpdatePerformanceReviewTargetNumericMonthly($input: UpdatePerformanceReviewTargetNumericMonthlyInput!) {
  updatePerformanceReviewTargetNumericMonthly(input: $input)
}
    `;
export type UpdatePerformanceReviewTargetNumericMonthlyMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewTargetNumericMonthlyMutation, UpdatePerformanceReviewTargetNumericMonthlyMutationVariables>;

/**
 * __useUpdatePerformanceReviewTargetNumericMonthlyMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewTargetNumericMonthlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewTargetNumericMonthlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewTargetNumericMonthlyMutation, { data, loading, error }] = useUpdatePerformanceReviewTargetNumericMonthlyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewTargetNumericMonthlyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewTargetNumericMonthlyMutation, UpdatePerformanceReviewTargetNumericMonthlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewTargetNumericMonthlyMutation, UpdatePerformanceReviewTargetNumericMonthlyMutationVariables>(UpdatePerformanceReviewTargetNumericMonthlyDocument, options);
      }
export type UpdatePerformanceReviewTargetNumericMonthlyMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewTargetNumericMonthlyMutation>;
export type UpdatePerformanceReviewTargetNumericMonthlyMutationResult = Apollo.MutationResult<UpdatePerformanceReviewTargetNumericMonthlyMutation>;
export type UpdatePerformanceReviewTargetNumericMonthlyMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewTargetNumericMonthlyMutation, UpdatePerformanceReviewTargetNumericMonthlyMutationVariables>;