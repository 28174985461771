import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GoogleOfflineOAuthCallbackMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  redirectUri: Types.Scalars['Url']['input'];
}>;


export type GoogleOfflineOAuthCallbackMutation = { __typename?: 'Mutation', googleOfflineOAuthCallback: boolean };


export const GoogleOfflineOAuthCallbackDocument = gql`
    mutation GoogleOfflineOAuthCallback($code: String!, $redirectUri: Url!) {
  googleOfflineOAuthCallback(code: $code, redirectUri: $redirectUri)
}
    `;
export type GoogleOfflineOAuthCallbackMutationFn = Apollo.MutationFunction<GoogleOfflineOAuthCallbackMutation, GoogleOfflineOAuthCallbackMutationVariables>;

/**
 * __useGoogleOfflineOAuthCallbackMutation__
 *
 * To run a mutation, you first call `useGoogleOfflineOAuthCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleOfflineOAuthCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleOfflineOAuthCallbackMutation, { data, loading, error }] = useGoogleOfflineOAuthCallbackMutation({
 *   variables: {
 *      code: // value for 'code'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useGoogleOfflineOAuthCallbackMutation(baseOptions?: Apollo.MutationHookOptions<GoogleOfflineOAuthCallbackMutation, GoogleOfflineOAuthCallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleOfflineOAuthCallbackMutation, GoogleOfflineOAuthCallbackMutationVariables>(GoogleOfflineOAuthCallbackDocument, options);
      }
export type GoogleOfflineOAuthCallbackMutationHookResult = ReturnType<typeof useGoogleOfflineOAuthCallbackMutation>;
export type GoogleOfflineOAuthCallbackMutationResult = Apollo.MutationResult<GoogleOfflineOAuthCallbackMutation>;
export type GoogleOfflineOAuthCallbackMutationOptions = Apollo.BaseMutationOptions<GoogleOfflineOAuthCallbackMutation, GoogleOfflineOAuthCallbackMutationVariables>;