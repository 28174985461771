import { EmployeeExportFormat } from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { ObjectUtils } from '@/shared/utils';
import { Button } from '@any-ui-react/core';
import { Select } from '@any-ui-react/select';
import { useState } from 'react';
import { toast } from 'src/components/shared';
import { AUTH_STATE_KEY } from 'src/pages/login';
import { TableSelection } from 'src/types/table';
import { ApiUtils } from 'src/utils/api.utils';
import { RouterUtils } from 'src/utils/router.utils';
import { getStorageItem } from 'src/utils/storage';
import {
  useExportEmployeesLazyQuery,
  useGenerateGoogleOfflineOAuthUrlLazyQuery,
  useVerifyGoogleOfflineTokenMutation,
} from '../../graphql';
import { EmployeeListItem } from '../../types';
import { EmployeeListFilterType } from '../employee-list-filter/EmployeeList.filters';

const options = [
  {
    label: 'General Information',
    value: EmployeeExportFormat.GeneralInformation,
  },
  {
    label: 'Personal Information',
    value: EmployeeExportFormat.PersonalInformation,
  },
  {
    label: 'Job / Contract Information',
    value: EmployeeExportFormat.JobHistoryContract,
  },
];

interface Props {
  selection: TableSelection<EmployeeListItem>;
  filters: FilterProps<EmployeeListFilterType>;
}

export const EmployeeListExport = ({ selection, filters }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [exportEmployees] = useExportEmployeesLazyQuery({
    onError(data) {
      toast.error(ApiUtils.getErrorMessage(data));
    },
    onCompleted(data) {
      RouterUtils.externalRedirect(data.exportEmployees, true);
    },
  });
  const [verifyGoogleOfflineToken] = useVerifyGoogleOfflineTokenMutation();
  const [generateGoogleOfflineOAuthUrl] =
    useGenerateGoogleOfflineOAuthUrlLazyQuery({
      onError(data) {
        toast.error(ApiUtils.getErrorMessage(data));
      },
    });

  const onExport = async (v: EmployeeExportFormat) => {
    setIsOpen(false);
    setLoading(true);
    const offlineToken = await verifyGoogleOfflineToken();

    if (!offlineToken.data?.verifyGoogleOfflineToken) {
      const authState = getStorageItem(AUTH_STATE_KEY);
      const response = await generateGoogleOfflineOAuthUrl({
        variables: {
          state: authState ?? '',
          redirectUri: process.env.REACT_APP_EMPLOYEE_CALLBACK_URL,
        },
      });

      const url = response.data?.generateGoogleOfflineOAuthUrl;
      RouterUtils.externalRedirect(url);
    }

    await exportEmployees({
      variables: {
        format: v,
        filter: ObjectUtils.deleteFields(filters.current, [
          'name',
          'id',
          'isOpenFilter',
          'page',
        ]),
        employeeIds: selection.items.map((s) => s.id),
      },
    });

    setLoading(false);
  };

  return (
    <Select
      menuIsOpen={isOpen}
      options={options}
      onChange={(v) => v && onExport(v?.value)}
      classNames={{
        container: () => 'w-[280px]',
      }}
      components={{
        Control: () => (
          <Button
            className='bg-white'
            variant='default'
            onClick={() => setIsOpen((prev) => !prev)}
            loading={loading}
          >
            Export
          </Button>
        ),
        Input: () => null,
        DropdownIndicator: null,
        SingleValue: () => <span>Export</span>,
        Placeholder: () => null,
      }}
    />
  );
};
