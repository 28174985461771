import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import { EmployeeRole, ListEmployeesSortInput } from '@/shared/graphql';
import { ObjectUtils } from '@/shared/utils';
import { Button, TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EmployeeListFilter,
  EmployeeListImportModal,
  EmployeeListTable,
  useEmployeeListFilter,
} from './components';
import { useListEmployeesQuery } from './graphql';

export const EmployeeList = () => {
  const [, scrollTo] = useWindowScroll();
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const { filters } = useEmployeeListFilter();
  const { page, ...currentFilter } = filters.current;

  const [sort, setSort] = useState<ListEmployeesSortInput | null>(null);

  const { data: employeeListData, loading } = useListEmployeesQuery({
    variables: {
      pageNumber: page,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: ObjectUtils.deleteFields(currentFilter, [
        'name',
        'id',
        'isOpenFilter',
      ]),
      sort,
    },
  });

  return (
    <>
      <PageTitle
        text='Employees'
        aside={() => (
          <>
            {![EmployeeRole.Basic, EmployeeRole.Advanced].includes(
              authUser.role
            ) && (
              <div className='flex space-x-4'>
                <EmployeeListImportModal />
                <Button
                  className='ml-auto bg-blue-6'
                  onClick={() => navigate('/employees/add')}
                >
                  Add Employee
                </Button>
              </div>
            )}
          </>
        )}
      />
      <Card withPadding={false}>
        <EmployeeListFilter filters={filters} />
        <EmployeeListTable
          employees={employeeListData?.listEmployees.items}
          loading={loading}
          page={page}
          filters={filters}
          sort={sort}
          setSort={setSort}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={page}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        withItems={true}
        count={employeeListData?.listEmployees.totalCount || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
