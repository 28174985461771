import { Card } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { ExternalAccountTable } from './external-account-table';

export const ExternalAccounts = () => {
  const { employeeDetail } = useEmployeeDetailContext();
  return (
    <Card withPadding={false}>
      <ExternalAccountTable items={employeeDetail.externalAccounts} />
    </Card>
  );
};
