import { QuarterSelect, YearPicker } from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { PerformanceReviewStageListFilterType } from './PerformanceReviewStageList.filters';

export const PerformanceReviewStageListFilter = ({
  filters,
}: {
  filters: FilterProps<PerformanceReviewStageListFilterType>;
}) => {
  const { changeFilter, current } = filters;

  return (
    <div className='grid grid-cols-8 gap-4 p-4'>
      <div className='col-span-2'>
        <YearPicker
          value={String(current.year)}
          onChange={(value) => changeFilter('year', Number(value))}
        />
      </div>
      <div className='col-span-2'>
        <QuarterSelect
          value={current.quarter}
          onChange={(value) => changeFilter('quarter', value)}
        />
      </div>
    </div>
  );
};
