import { ExternalAccountTimingToCreate } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeExternalAccountFormData = z.object({
  autoCreateGoogleWorkspace: z.boolean().default(false),
  autoCreateGreenhouse: z.boolean().default(false),
  timingToCreateAccounts: z
    .nativeEnum(ExternalAccountTimingToCreate)
    .default(ExternalAccountTimingToCreate.SevenDaysBefore),
});
export type EmployeeExternalAccountFormDataType = z.infer<
  typeof EmployeeExternalAccountFormData
>;
