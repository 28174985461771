import { useFilters } from '@/shared/hooks';
import { DateUtils } from '@/shared/utils';
import {
  PerformanceReviewResultListFilterType,
  PerformanceReviewResultListParser,
} from './PerformanceReviewResultList.filters';

export const usePerformanceReviewResultListFilter = () => {
  const filters = useFilters<PerformanceReviewResultListFilterType>(
    {
      year: new Date().getFullYear(),
      quarter: DateUtils.getCurrentPerformanceReviewQuarter(),
      keyword: '',
      contractTypes: [],
      companyIds: [],
      locationIds: [],
      businessUnitIds: [],
      departmentIds: [],
      levels: [],
      page: 1,
    },
    {
      parser: PerformanceReviewResultListParser.parse,
    }
  );

  return { filters };
};
