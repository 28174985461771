import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCompaniesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
}>;


export type ListCompaniesQuery = { __typename?: 'Query', listCompanies: { __typename?: 'ListCompanies', totalCount: number, items: Array<{ __typename?: 'Company', id: string, name: string, countryId: string, probationReviewTimes: number, googleOrgUnitPath: string, enabled: boolean, createdAt: any, updatedAt: any, country: { __typename?: 'Country', id: string, name: string }, probationReviewTerm: { __typename?: 'CompanyProbationReviewTerm', length: number, mode: Types.CompanyProbationReviewTermMode } }> } };


export const ListCompaniesDocument = gql`
    query listCompanies($pageNumber: Int!, $pageSize: Int!) {
  listCompanies(pageNumber: $pageNumber, pageSize: $pageSize) {
    items {
      id
      name
      country {
        id
        name
      }
      countryId
      probationReviewTerm {
        length
        mode
      }
      probationReviewTimes
      googleOrgUnitPath
      enabled
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a React component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListCompaniesQuery(baseOptions: Apollo.QueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, options);
      }
export function useListCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, options);
        }
export function useListCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, options);
        }
export type ListCompaniesQueryHookResult = ReturnType<typeof useListCompaniesQuery>;
export type ListCompaniesLazyQueryHookResult = ReturnType<typeof useListCompaniesLazyQuery>;
export type ListCompaniesSuspenseQueryHookResult = ReturnType<typeof useListCompaniesSuspenseQuery>;
export type ListCompaniesQueryResult = Apollo.QueryResult<ListCompaniesQuery, ListCompaniesQueryVariables>;