import { ProbationReviewDetailType } from '@/shared/types';
import { create, each, enforce, test } from 'vest';

export const revieweeFormValidationSuite = create(
  ({ stages }: ProbationReviewDetailType) => {
    each(stages, (stage, index) => {
      test(`stages.${index}.selfFeedback`, 'Self feedback is required', () => {
        enforce(stage.selfFeedback).isNotBlank();
      });
    });
  }
);
