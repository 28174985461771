import { Button } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';
import { v4 as uuid } from 'uuid';
import { EmployeeListImportModalForm } from './EmployeeListImportModalForm';

// TODO!: Implement when API is already available

export const EmployeeListImportModal = () => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      modalId,
      size: 'md',
      title: 'Import',
      children: (
        <EmployeeListImportModalForm onClose={() => closeModal(modalId)} />
      ),
    });
  };

  return (
    <Button variant='default' className='bg-white' onClick={onClick}>
      Import CSV
    </Button>
  );
};
