import { EMPTY_DEFAULT } from '../components';

export class StringUtils {
  static capitalizeAll = (s: string, delimiter: string = '') => {
    return s
      .split(delimiter)
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ');
  };

  static pluralize = (count: number, word: string, suffix = 's') => {
    return count === 1 ? word : `${word}${suffix}`;
  };

  static withFallback = (
    value: string | undefined | null,
    fallback = EMPTY_DEFAULT
  ) => {
    return value || fallback;
  };
}
