import {
  LockIcon,
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
} from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import { EmployeeRole } from '@/shared/graphql';
import { DateUtils } from '@/shared/utils';
import { useMemo } from 'react';
import { EmployeeCustomFilterType } from './EmployeeCustomFilters.filters';

interface CustomFilterTableProps {
  loading: boolean;
  items: EmployeeCustomFilterType[];
  onRemove: (id: string) => void;
}

export const CustomFilterTable = ({
  items,
  loading,
  onRemove,
}: CustomFilterTableProps) => {
  const { authUser } = useAuthContext();
  const isRemovable = authUser.role === EmployeeRole.SuperAdmin;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (item: EmployeeCustomFilterType) => {
            return (
              <div className='flex'>
                {item.isOpenFilter && (
                  <LockIcon
                    className='mr-1 self-center'
                    width={14}
                    height={14}
                  />
                )}
                <span>{item.name}</span>
              </div>
            );
          },
        },
        {
          headerRender: () => 'Created By',
          accessor: 'createdBy',
          cellRender: (item: EmployeeCustomFilterType) => {
            return item.createdBy;
          },
        },
        {
          headerRender: () => 'Created At',
          accessor: 'createdAt',
          cellRender: (item: EmployeeCustomFilterType) => {
            return <span>{DateUtils.formatDatetime(item.createdAt)}</span>;
          },
        },
        ...(isRemovable
          ? [
              {
                headerRender: () => '',
                accessor: 'delete',
                cellRender: (item: EmployeeCustomFilterType) => {
                  return (
                    <TrashIcon
                      onClick={() => {
                        onRemove(item.id);
                      }}
                      className='ml-auto h-4 w-4  cursor-pointer text-gray-7'
                    />
                  );
                },
              },
            ]
          : []),
      ] as TableLayout<EmployeeCustomFilterType>[],
    [isRemovable, onRemove]
  );

  const tableCommonProps: TableProps<EmployeeCustomFilterType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <Table<EmployeeCustomFilterType>
      {...tableCommonProps}
      page={1}
      allowFloatingHeader
      rowClassName='h-12'
    />
  );
};
