import { useMemo } from 'react';

import { DivisionType, TableSelection } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { DivisionModal } from '../division-modal';

interface DivisionTableProps {
  loading: boolean;
  items: DivisionType[];
  selection: TableSelection<DivisionType>;
  setSelection: (selection: TableSelection<DivisionType>) => void;
  total: number;
}

export const DivisionTable = ({
  items,
  loading,
  total,
  selection,
}: DivisionTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (division: DivisionType) => {
            return (
              <DivisionModal division={division}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {division.name}
                </span>
              </DivisionModal>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: DivisionType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: DivisionType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: DivisionType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<DivisionType>[],
    []
  );

  const tableCommonProps: TableProps<DivisionType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Division Item Title'}
        description={'There are no Division Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<DivisionType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
