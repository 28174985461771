import { Calendar, EMPTY_DEFAULT } from '@/shared/components';
import { ProbationReviewDetailType } from '@/shared/types';
import { DateUtils, NumberUtils } from '@/shared/utils';
import { Badge, Button, Input, ModalFooter } from '@any-ui-react/core';
import { format } from 'date-fns';
import { useState } from 'react';

interface Props {
  stages: ProbationReviewDetailType['stages'];
  onAdd: (date: Date) => void;
  onCloseModal: () => void;
  hiredDate?: string | null;
}

export const AddNewProbationReviewFields = ({
  stages,
  onAdd,
  onCloseModal,
  hiredDate,
}: Props) => {
  const [date, setDate] = useState<Date>();
  const [error, setError] = useState('');

  return (
    <div>
      <div className='flex w-full space-x-6'>
        <div className='border px-6 py-4'>
          <Calendar
            value={date}
            onChange={(date) => {
              setError('');
              setDate(date);
            }}
            minDate={new Date(stages.slice(-1)[0]?.deadline)}
          />
        </div>
        <div className='flex-1 border px-4 py-6'>
          <div className='pb-4 text-sm font-semibold'>
            Probation Review Start Date
          </div>
          <div className='flex w-full justify-between py-2 text-sm text-gray-7'>
            <span>Hired Date</span>
            <span>
              {hiredDate
                ? DateUtils.formatDate(new Date(hiredDate), 'MMM dd, yyyy')
                : EMPTY_DEFAULT}
            </span>
          </div>
          <div className='flex flex-col divide-y border-y text-sm text-gray-7'>
            {stages.map((stage, index) => (
              <div className='flex w-full justify-between py-2'>
                <span>{NumberUtils.ordinal(index + 1)} Review</span>
                <span>{format(new Date(stage.deadline), 'MMM dd, yyyy')}</span>
              </div>
            ))}
          </div>

          {date && (
            <div className='my-4 flex justify-between text-sm font-semibold'>
              <span>{NumberUtils.ordinal(stages.length + 1)} Review</span>
              <div className='flex items-center space-x-1'>
                <Badge color='yellow' size='sm'>
                  new
                </Badge>
                <span>{format(date, 'MMM dd, yyyy')}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Input.Error className='my-6'>{error}</Input.Error>

      <ModalFooter>
        <Button variant='default' onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          onClick={() => {
            if (date) {
              onAdd(date);
            } else {
              setError('Please select a date');
            }
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};
