import {
  EmployeeListSortField,
  ListEmployeesSortInput,
} from '@/shared/graphql';
import classNames from 'classnames';
import { ChevronUpIcon } from 'src/components/shared';

interface Props {
  field: EmployeeListSortField;
  setSort: (sort: ListEmployeesSortInput | null) => void;
  sort: ListEmployeesSortInput | null;
  label: string;
}

export const SortableHeader = ({ field, sort, setSort, label }: Props) => {
  const isFieldActive = sort?.field === field;
  return (
    <div className='flex space-x-1.5'>
      <span>{label}</span>
      <div className='flex flex-col content-between space-y-1'>
        <ChevronUpIcon
          className={classNames('cursor-pointer  hover:text-gray-7', {
            'text-gray-7': isFieldActive && sort.desc,
            'text-gray-5': !isFieldActive,
          })}
          onClick={() =>
            setSort({
              field,
              desc: false,
            })
          }
        />
        <ChevronUpIcon
          className={classNames('rotate-180 cursor-pointer hover:text-gray-7', {
            'text-gray-7': isFieldActive && !sort.desc,
            'text-gray-5': !isFieldActive,
          })}
          onClick={() =>
            setSort({
              field,
              desc: true,
            })
          }
        />
      </div>
    </div>
  );
};
