import {
  EMPTY_DEFAULT,
  NoResult,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import { ProbationReviewStatus } from '@/shared/graphql';
import { ProbationReviewType } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Badge } from '@any-ui-react/core';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface EmployeeTableProps {
  loading: boolean;
  items: ProbationReviewType[];
}

export const ProbationReviewTable = ({
  items,
  loading,
}: EmployeeTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Employee Name',
          accessor: 'preferredFullName',
          cellRender: ({ employeeName, employeeId }) => (
            <Link to={`/employees/edit/${employeeId}?tab=probation-review`}>
              <span className='text-blue-6 hover:cursor-pointer'>
                {employeeName}
              </span>
            </Link>
          ),
        },
        {
          headerRender: () => 'Hired Date',
          accessor: 'hiredDate',
          cellRender: ({ hiredDate }) =>
            hiredDate
              ? DateUtils.formatDate(hiredDate, 'MMM dd, yyyy')
              : EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Company',
          accessor: 'companyName',
          cellRender: ({ companyName }) => companyName,
        },
        {
          headerRender: () => 'Office Location',
          accessor: 'officeLocation',
          cellRender: ({ officeLocation }) => officeLocation || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: ({ businessUnit }) => businessUnit || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Level',
          accessor: 'level',
          cellRender: ({ level }) => level || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Primary Manager',
          accessor: 'primaryManager',
          cellRender: ({ primaryManager }) => primaryManager || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Planned Review Date',
          accessor: 'reviewDates',
          cellRender: ({ reviewDates }) => {
            return (
              <div className='flex flex-col'>
                {reviewDates.map((date, index) => (
                  <span key={index}>
                    {format(new Date(date), 'MMM dd, yyyy')}
                  </span>
                ))}
              </div>
            );
          },
        },
        {
          headerRender: () => 'Status',
          accessor: 'status',
          cellRender: ({ status }) => {
            const getStatusColor = (status: ProbationReviewStatus) => {
              switch (status) {
                case ProbationReviewStatus.Submitted:
                  return 'green';
                case ProbationReviewStatus.Overdue:
                  return 'red';
                case ProbationReviewStatus.Draft:
                default:
                  return 'gray';
              }
            };
            return (
              <Badge className='capitalize' color={getStatusColor(status)}>
                {StringUtils.capitalizeAll(status, '_')}
              </Badge>
            );
          },
        },
      ] as TableLayout<ProbationReviewType>[],
    []
  );

  const tableCommonProps: TableProps<ProbationReviewType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<ProbationReviewType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
