import { Spinner } from '@/shared/components';
import { useEmployeeAccess } from '@/shared/hooks';
import { useEmployeeDetailContext } from 'src/contexts/EmployeeDetailContext';
import { RevieweeForm } from './probation-form-reviewee';
import { ReviewerForm } from './probation-form-reviewer';

export const ProbationReview = () => {
  const { isMyOwnData } = useEmployeeAccess();
  const { employeeDetail } = useEmployeeDetailContext();

  if (!employeeDetail) {
    return (
      <div className='my-8'>
        <Spinner />
      </div>
    );
  }

  if (isMyOwnData) {
    return <RevieweeForm />;
  }

  return <ReviewerForm />;
};
