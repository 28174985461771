import { JobHistoryLevel } from '@/shared/graphql';
import { z } from 'zod';

export const ProbationReviewFilter = z.object({
  keyword: z.string().nullable().optional().catch(null),
  page: z.number().catch(1),
  companyIds: z.array(z.string()).catch([]),
  businessUnitIds: z.array(z.string()).catch([]),
  levels: z.array(z.nativeEnum(JobHistoryLevel)).catch([]),
  reviewerIds: z.array(z.string()).catch([]),
  primaryManagerIds: z.array(z.string()).catch([]),
  isOverDue: z.boolean().default(false),
});
export type ProbationReviewFilterType = z.infer<typeof ProbationReviewFilter>;
