export class NumberUtils {
  static formatDecimalToPercent = (decimal: number): string => {
    return `${Math.round(decimal * 100)}%`;
  };

  static ordinal = (n: number): string => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };
}
