import { EmergencyContactRelation } from '@/shared/graphql';
import { z } from 'zod';

export const EmergencyContact = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  relation: z
    .nativeEnum(EmergencyContactRelation)
    .default(EmergencyContactRelation.Other),
  phoneNumberCountryCode: z.string().default(''),
  phoneNumber: z.string().default(''),
  remarks: z.string().default(''),
});
export type EmergencyContactType = z.infer<typeof EmergencyContact>;
