import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListPerformanceReviewResultsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.ListPerformanceReviewResultFilterInput;
}>;


export type ListPerformanceReviewResultsQuery = { __typename?: 'Query', listPerformanceReviewResults: { __typename?: 'ListPerformanceReviewResults', totalCount: number, items: Array<{ __typename?: 'PerformanceReviewResult', performanceReviewId: string, growthTarget: any, employee: { __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string, legalFullName: string }, currentContract: { __typename?: 'EmployeeContract', contractType: Types.ContractType, company: { __typename?: 'Company', name: string } } | null, probationReview: { __typename?: 'ProbationReview', stages: Array<{ __typename?: 'ProbationReviewStage', deadline: any }> } | null, currentPosition: { __typename?: 'EmployeeJobHistory', level: Types.JobHistoryLevel, jobTitle: string, location: { __typename?: 'Location', name: string }, businessUnit: { __typename?: 'BusinessUnit', name: string }, department: { __typename?: 'Department', name: string } } | null }, performanceTarget: { __typename?: 'PerformanceReviewResultPerformanceTarget', all: { __typename?: 'PerformanceReviewResultResult', score: any, weight: number }, numeric: { __typename?: 'PerformanceReviewResultResult', score: any }, impactFocused: { __typename?: 'PerformanceReviewResultResult', score: any } }, value: { __typename?: 'PerformanceReviewResultResult', score: any } }> } };


export const ListPerformanceReviewResultsDocument = gql`
    query ListPerformanceReviewResults($pageNumber: Int!, $pageSize: Int!, $filter: ListPerformanceReviewResultFilterInput!) {
  listPerformanceReviewResults(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
  ) {
    totalCount
    items {
      performanceReviewId
      employee {
        id
        generalInformation {
          preferredFullName
          legalFullName
        }
        currentContract {
          contractType
        }
        probationReview {
          stages {
            deadline
          }
        }
        currentContract {
          company {
            name
          }
        }
        currentPosition {
          level
          location {
            name
          }
          businessUnit {
            name
          }
          department {
            name
          }
          jobTitle
        }
      }
      performanceTarget {
        all {
          score
          weight
        }
        numeric {
          score
        }
        impactFocused {
          score
        }
      }
      value {
        score
      }
      growthTarget
    }
  }
}
    `;

/**
 * __useListPerformanceReviewResultsQuery__
 *
 * To run a query within a React component, call `useListPerformanceReviewResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPerformanceReviewResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPerformanceReviewResultsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPerformanceReviewResultsQuery(baseOptions: Apollo.QueryHookOptions<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>(ListPerformanceReviewResultsDocument, options);
      }
export function useListPerformanceReviewResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>(ListPerformanceReviewResultsDocument, options);
        }
export function useListPerformanceReviewResultsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>(ListPerformanceReviewResultsDocument, options);
        }
export type ListPerformanceReviewResultsQueryHookResult = ReturnType<typeof useListPerformanceReviewResultsQuery>;
export type ListPerformanceReviewResultsLazyQueryHookResult = ReturnType<typeof useListPerformanceReviewResultsLazyQuery>;
export type ListPerformanceReviewResultsSuspenseQueryHookResult = ReturnType<typeof useListPerformanceReviewResultsSuspenseQuery>;
export type ListPerformanceReviewResultsQueryResult = Apollo.QueryResult<ListPerformanceReviewResultsQuery, ListPerformanceReviewResultsQueryVariables>;