import { EmployeeOffBoardingFormDataType } from '@/shared/types';
import { isValid } from 'date-fns';
import { create, enforce, omitWhen, test } from 'vest';

export const offBoardingFormValidator = create(
  ({ employmentEndDate, lastWorkingDate }: EmployeeOffBoardingFormDataType) => {
    const employmentEndDateParsed = new Date(employmentEndDate ?? '');
    const lastWorkingDateParsed = new Date(lastWorkingDate ?? '');

    omitWhen(!lastWorkingDate, () => {
      test('employmentEndDate', 'Employment End Date is required', () => {
        enforce(employmentEndDate).isNotEmpty();
      });
    });

    omitWhen(!employmentEndDate, () => {
      test('lastWorkingDate', 'Last Working Date is required', () => {
        enforce(lastWorkingDate).isNotEmpty();
      });
    });

    omitWhen(!employmentEndDate, () => {
      test(
        'employmentEndDate',
        'Employment End Date must be a valid date',
        () => {
          enforce(isValid(new Date(employmentEndDateParsed))).isTruthy();
        }
      );
    });

    omitWhen(!lastWorkingDate, () => {
      test('lastWorkingDate', 'Last Working Date must be a valid date', () => {
        enforce(isValid(new Date(lastWorkingDateParsed))).isTruthy();
      });
    });

    omitWhen(!lastWorkingDate && !employmentEndDate, () => {
      test(
        'employmentEndDate',
        'Employment End Date must be a greater than Last Working Date',
        () => {
          const isEndDateGreater =
            employmentEndDateParsed >= lastWorkingDateParsed;
          enforce(isEndDateGreater).isTruthy();
        }
      );

      test(
        'lastWorkingDate',
        'Last Working Date must be a less than Employment End Date',
        () => {
          const isStartDateLess =
            lastWorkingDateParsed <= employmentEndDateParsed;
          enforce(isStartDateLess).isTruthy();
        }
      );
    });
  }
);
