import { PerformanceReviewQuarter } from '@/shared/graphql';
import { DateUtils } from 'src/utils/date.utils';
import { z } from 'zod';

export const PerformanceReviewStageListParser = z.object({
  year: z.number().default(new Date().getFullYear()),
  quarter: z
    .nativeEnum(PerformanceReviewQuarter)
    .catch(DateUtils.getCurrentPerformanceReviewQuarter()),
  page: z.number().catch(1),
});

export type PerformanceReviewStageListFilterType = z.infer<
  typeof PerformanceReviewStageListParser
>;
