import { ContractTypeForReport } from '@/shared/graphql';
import { format } from 'date-fns';
import { z } from 'zod';

export const DemographicReportFilter = z.object({
  yearMonth: z.string().default(format(new Date(), 'yyyy-MM')),
  contractTypes: z.array(z.nativeEnum(ContractTypeForReport)).catch([]),
  countryIds: z.array(z.string()).catch([]),
  locationIds: z.array(z.string()).catch([]),
  companyIds: z.array(z.string()).catch([]),
  businessUnitIds: z.array(z.string()).catch([]),
  departmentIds: z.array(z.string()).catch([]),
  jobIds: z.array(z.string()).catch([]),
  nationalityIds: z.array(z.string()).catch([]),
});

export type DemographicReportFilterType = z.infer<
  typeof DemographicReportFilter
>;
