import { toast } from '@/shared/components';
import {
  ProbationReviewResult,
  useSubmitProbationReviewMutation,
} from '@/shared/graphql';
import { ProbationReviewDetailType } from '@/shared/types';
import { Button, ModalFooter, Radio } from '@any-ui-react/core';
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { ApiUtils } from 'src/utils/api.utils';

interface Props {
  employeeId: string;
  control: Control<ProbationReviewDetailType>;
  setValue: UseFormSetValue<ProbationReviewDetailType>;
  handleSubmit: UseFormHandleSubmit<ProbationReviewDetailType>;
  onCloseModal: () => void;
}

export const SubmitReviewModalContent = ({
  employeeId,
  control,
  setValue,
  handleSubmit,
  onCloseModal,
}: Props) => {
  const [submitProbationReview, { loading }] = useSubmitProbationReviewMutation(
    {
      onCompleted() {
        toast.success();
        onCloseModal();
      },
      onError(data) {
        toast.error(ApiUtils.getErrorMessage(data));
      },
    }
  );

  const handleSubmitForApproval = () => {
    handleSubmit(async (data) => {
      await submitProbationReview({
        variables: {
          input: {
            employeeId: employeeId,
            expectation: data.expectation,
            result: data.result ?? ProbationReviewResult.Hold,
            stages: data.stages.map((stage) => ({
              reviewerId: stage.reviewerId,
              deadline: stage.deadline,
              reviewerFeedback: stage.reviewerFeedback ?? '',
            })),
          },
        },
      });
    })();

    onCloseModal();
  };
  return (
    <div className='flex flex-col space-y-6'>
      <span>
        Please evaluate whether the employee has passed or failed the Probation
        Review and proceed with the Final Submission
      </span>

      <Controller
        control={control}
        name='result'
        render={({ field: { value, ...fields } }) => {
          return (
            <div className='flex flex-col space-y-4'>
              <Radio.Group
                {...fields}
                value={value ?? undefined}
                onChange={(newValue) =>
                  setValue('result', newValue as ProbationReviewResult)
                }
              >
                <div className='flex flex-col space-y-4'>
                  <Radio
                    label='I advise passing this employee on probation.'
                    value={ProbationReviewResult.Passed}
                  />
                  <Radio
                    label='I suggest to hold an internal discussion on whether we pass the employee’s probation or not.'
                    value={ProbationReviewResult.Hold}
                  />
                </div>
              </Radio.Group>
            </div>
          );
        }}
      />

      <ModalFooter>
        <Button
          variant='default'
          onClick={() => {
            setValue('result', null);
            onCloseModal();
          }}
        >
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={loading}
          onClick={handleSubmitForApproval}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};
