import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { ProbationReviewDetailType } from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';
import { v4 as uuid } from 'uuid';
import { AddNewProbationReviewFields } from './AddNewProbationReviewFields';

interface Props {
  stages: ProbationReviewDetailType['stages'];
  onAddProbationReview: (date: Date) => void;
}

export const AddNewProbationReview = ({
  stages,
  onAddProbationReview,
}: Props) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const { isMyOwnData, hasProbationReviewModifyAccess, loading } =
    useEmployeeAccess();
  const { employeeDetail } = useEmployeeDetailContext();

  if (loading || isMyOwnData || !hasProbationReviewModifyAccess) {
    return null;
  }

  const onClick = async () => {
    openModal({
      modalId,
      title: 'Create a New Probation Review',
      size: 'xl',
      children: (
        <AddNewProbationReviewFields
          stages={stages}
          onAdd={(date) => {
            onAddProbationReview(date);
            closeModal(modalId);
          }}
          onCloseModal={() => closeModal(modalId)}
          hiredDate={employeeDetail.generalInformation.hiredDate}
        />
      ),
    });
  };

  return (
    <>
      <Button variant='default' className='bg-white' onClick={onClick}>
        Add a new Probation Review
      </Button>
    </>
  );
};
