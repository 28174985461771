import { useFilters } from '@/shared/hooks';
import { DateUtils } from '@/shared/utils';
import {
  PerformanceReviewStageListFilterType,
  PerformanceReviewStageListParser,
} from './PerformanceReviewStageList.filters';

export const usePerformanceReviewStageListFilter = () => {
  const filters = useFilters<PerformanceReviewStageListFilterType>(
    {
      year: new Date().getFullYear(),
      quarter: DateUtils.getCurrentPerformanceReviewQuarter(),
      page: 1,
    },
    {
      parser: PerformanceReviewStageListParser.parse,
    }
  );

  return { filters };
};
