import { useMemo } from 'react';

import { DepartmentType, TableSelection } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { DepartmentModal } from '../department-modal';

interface DepartmentTableProps {
  loading: boolean;
  items: DepartmentType[];
  selection: TableSelection<DepartmentType>;
  setSelection: (selection: TableSelection<DepartmentType>) => void;
  total: number;
}

export const DepartmentTable = ({
  items,
  loading,
  total,
  selection,
}: DepartmentTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (department: DepartmentType) => {
            return (
              <DepartmentModal department={department}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {department.name}
                </span>
              </DepartmentModal>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: DepartmentType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: DepartmentType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: DepartmentType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<DepartmentType>[],
    []
  );

  const tableCommonProps: TableProps<DepartmentType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Department Item Title'}
        description={'There are no Department Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<DepartmentType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
