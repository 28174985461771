import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewSettingMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewSettingInput;
}>;


export type UpdatePerformanceReviewSettingMutation = { __typename?: 'Mutation', updatePerformanceReviewSetting: { __typename?: 'PerformanceReviewOutput', id: string } };


export const UpdatePerformanceReviewSettingDocument = gql`
    mutation UpdatePerformanceReviewSetting($input: UpdatePerformanceReviewSettingInput!) {
  updatePerformanceReviewSetting(input: $input) {
    id
  }
}
    `;
export type UpdatePerformanceReviewSettingMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewSettingMutation, UpdatePerformanceReviewSettingMutationVariables>;

/**
 * __useUpdatePerformanceReviewSettingMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewSettingMutation, { data, loading, error }] = useUpdatePerformanceReviewSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewSettingMutation, UpdatePerformanceReviewSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewSettingMutation, UpdatePerformanceReviewSettingMutationVariables>(UpdatePerformanceReviewSettingDocument, options);
      }
export type UpdatePerformanceReviewSettingMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewSettingMutation>;
export type UpdatePerformanceReviewSettingMutationResult = Apollo.MutationResult<UpdatePerformanceReviewSettingMutation>;
export type UpdatePerformanceReviewSettingMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewSettingMutation, UpdatePerformanceReviewSettingMutationVariables>;