import { Button, ModalFooter, TextInput } from '@any-ui-react/core';

import { DatePicker, EmployeeCompanyAssetSelect } from '@/shared/components';
import { EmployeeCompanyAssetType as NativeEmployeeCompanyAssetType } from '@/shared/graphql';
import {
  CompanyAssetFormData,
  CompanyAssetFormDataType,
  EmployeeCompanyAsset,
  EmployeeCompanyAssetType,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { companyAssetFormValidationSuite } from './company-asset-form.validator';

export interface CompanyAssetFormProps {
  companyAsset?: EmployeeCompanyAssetType;
  onSuccess: (data: EmployeeCompanyAssetType) => void;
  onCancel: () => void;
}

export const CompanyAssetForm = ({
  companyAsset,
  onSuccess,
  onCancel,
}: CompanyAssetFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm<CompanyAssetFormDataType>({
    defaultValues: CompanyAssetFormData.parse({ ...companyAsset }),
    resolver: vestResolver(companyAssetFormValidationSuite),
  });

  const [assignedDate, returnedDate] = watch(['assignedDate', 'returnedDate']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmployeeCompanyAsset.parse({ ...formValues, id: companyAsset?.id })
        );
      }),
    [companyAsset?.id, handleSubmit, onSuccess]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <EmployeeCompanyAssetSelect
          {...register('assetType')}
          defaultValue={companyAsset?.assetType}
          className='col-span-2'
          error={errors.assetType?.message}
          required
          label={'Asset Type'}
          placeholder={'Asset Type'}
          onChange={(value) => {
            setValue('assetType', value as NativeEmployeeCompanyAssetType);
            clearErrors('assetType');
          }}
        />
        <TextInput
          {...register('assetName')}
          required
          label={'Asset Name'}
          error={errors.assetName?.message}
          placeholder={'Asset Name'}
          className='col-span-2'
        />
        <TextInput
          {...register('assetNumber')}
          required
          label={'Asset Number'}
          error={errors.assetNumber?.message}
          placeholder={'Asset Number'}
          className='col-span-2'
        />

        <div className='col-span-2'>
          <DatePicker
            error={errors.assignedDate?.message}
            label='Assigned Date'
            required
            placeholder='Select Assigned Date'
            value={assignedDate || undefined}
            onChange={(value) => {
              setValue(
                'assignedDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('assignedDate');
            }}
          />
        </div>

        <div className='col-span-2'>
          <DatePicker
            label='Returned Date'
            error={errors.returnedDate?.message}
            placeholder='Select Returned Date'
            value={returnedDate || undefined}
            onChange={(value) => {
              setValue(
                'returnedDate',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('returnedDate');
            }}
          />
        </div>
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
