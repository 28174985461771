import { v4 as uuid } from 'uuid';

export const allCharts =
  'https://app.organimi.com/public/organizations/5885f36cae445f0400fb0b2d/charts/5940ef595ea609040064e1b0/chart/view?pId=5ba1b96eaab610ef5989f81437f2550ef295f5819bc44077ff72e71ec12d883b';

export const chartsData = [
  {
    id: uuid(),
    label: 'AnyMind Group',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/6703656129b6781b52a7e914/chart/view?pId=97e6599440b3f326185e05ec607d7eb1791fab038071e2c71fd38174d27dac5c',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/6703656129b6781b52a7e914/chart/view',
  },
  {
    id: uuid(),
    label: '[ALL] People',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/66fa5a27d56fdf8bb82a38ed/chart/view?pId=45b5c767116fd630a1f0fe4e4a85ea5e6a9b01a1ba903926ccb833735ae047cf',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/66fa5a27d56fdf8bb82a38ed/chart/view',
  },
  {
    id: uuid(),
    label: '[ALL] Performance Business',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5eaffc6b07bd26001583d76b/chart/view?pId=b3100ceb8e58be91c53b2bf02ec563be8b9d2066eec2001228aa83284ade698f',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5eaffc6b07bd26001583d76b/chart/view',
  },
  {
    id: uuid(),
    label: '[ALL] TECH',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/60e7e539e8ad7f0017eba00b/chart/view?pId=dc27a7154f589a736714f114cc907ad723e793795db75183c28b825579cc2280',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/60e7e539e8ad7f0017eba00b/chart/view',
  },
  {
    id: uuid(),
    label: '[HK] AnyMind Hong Kong Limited',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/59afa7814e5de504008fb1c5/chart/view?pId=33138d30bb9254bf07d10e5f37d8703a42870b8baf9b068c2ffcca0b19c71a52',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/59afa7814e5de504008fb1c5/chart/view',
  },
  {
    id: uuid(),
    label: '[ID] PT AdAsia Technology Indonesia',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5d2ec960ed73560013955439/chart/view?pId=81c957d66cdff02b3cc0a97e0e6d60058f254bc61f509a83d9444fbed65d7bfe',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5d2ec960ed73560013955439/chart/view',
  },
  {
    id: uuid(),
    label: '[IN] POKKT India',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/61aef5136e47dc0017caff41/chart/view?pId=9e92f256c595d63cf94f63c4c48351f3e01e9e87cd059296581e16ba28f8909e',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/61aef5136e47dc0017caff41/chart/view',
  },
  {
    id: uuid(),
    label: '[JP] AnyMind Japan Inc.',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5940ef595ea609040064e1b0/chart/view?pId=756c69ee0aa8260aa8c4714947673bc58593e42772f7c47f7d29c9662af719f6',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5940ef595ea609040064e1b0/chart/view',
  },
  {
    id: uuid(),
    label: '[JP] ENGAWA Inc.',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/60a5c7f292769e0016e475f8/chart/view?pId=f16f7ace797df607a623d5dbe02885ba9d5852bdc3118e31e9e351efc8d4ffe4',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/60a5c7f292769e0016e475f8/chart/view',
  },
  {
    id: uuid(),
    label: '[JP] GROVE Inc.',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/60a5dc6ca27eeb0016c278e4/chart/view?pId=f038a2755d69f1198a7d1e255bd0956dbd1f093849dd03fa5a6abd61b0c78efc',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/60a5dc6ca27eeb0016c278e4/chart/view',
  },
  {
    id: uuid(),
    label: '[KH] Cambodia',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/62f0c87530f1f10c1d8e57e6/chart/view?pId=4bd14ad767d6859acdcfbf9e976d6201e8e42de1f21899ca773b2f7c4d95b50f',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/62f0c87530f1f10c1d8e57e6/chart/view',
  },
  {
    id: uuid(),
    label: '[MENA] AnyTag MENA',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/61dffb79b321ac0017510a0a/chart/view?pId=ed73d04540a574f1774b6b0515da4851ea9b5146e710f7869a098ba8cd565f07',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/61dffb79b321ac0017510a0a/chart/view',
  },
  {
    id: uuid(),
    label: '[MY] AnyMind Malaysia',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/6375d4f7eceac77aec64fa98/chart/view?pId=324221288347bf86a52521f10d29e3fd905925c3f2a6ca15eb85882ac9d4160e',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/6375d4f7eceac77aec64fa98/chart/view',
  },
  {
    id: uuid(),
    label: '[PH] AnyMind Philippines',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5cde23b23cc4db0013421d9b/chart/view?pId=d94665c4caf2250a8973ab98cfb212bfd5cb47df59e662de5be7f517eb588a08',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5cde23b23cc4db0013421d9b/chart/view',
  },
  {
    id: uuid(),
    label: '[SG] AnyMind Singapore',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/63105f03c9490f88012f9b03/chart/view?pId=f76d22a5ccec89de2d607c2882b0b9a2c564c394cc3c2806b7e90859a9d2f289',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/63105f03c9490f88012f9b03/chart/view',
  },
  {
    id: uuid(),
    label: '[TH] AnyMind Thailand Limited',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5885f391330852040065ceeb/chart/view?pId=0c3881b8aa6cd4946c3cad38a02480d2e6fb0243a95e52b4e207b7dbf44f0c64',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5885f391330852040065ceeb/chart/view',
  },
  {
    id: uuid(),
    label: '[TW] AnyMind Taiwan',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/591045e566a3520400bc97df/chart/view?pId=abcd93eeb2e2abc07617958c249c01ab4bfae18f843b74b7fb71a16840600da9',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/591045e566a3520400bc97df/chart/view',
  },
  {
    id: uuid(),
    label: '[VN] AnyMind Group (AMV+AVN+AME+ATV)',
    embedLink:
      'https://app.organimi.com/embed/organizations/5885f36cae445f0400fb0b2d/charts/5f87a8303916290016b992b1/chart/view?pId=773cda1ce001342a2ad029e1a6056e8cf78c622e3d26dcbfe67d5d495ff54fa1',
    link: 'https://app.organimi.com/protected/organizations/5885f36cae445f0400fb0b2d/charts/5f87a8303916290016b992b1/chart/view',
  },
];
