import { useFilters } from '@/shared/hooks';
import {
  PerformanceReviewFilterType,
  PerformanceReviewParser,
  PerformanceReviewTab,
} from './PerformanceReview.filters';

export const usePerformanceReviewFilter = () => {
  const filters = useFilters<PerformanceReviewFilterType>(
    {
      tab: PerformanceReviewTab.OVERVIEW,
    },
    {
      parser: PerformanceReviewParser.parse,
    }
  );

  return { filters };
};
