import {
  PerformanceReviewQuarter,
  PerformanceReviewStatus,
} from '@/shared/graphql';
import { z } from 'zod';

export const PerformanceReviewDetail = z.object({
  id: z.string(),
  year: z.number(),
  quarter: z.nativeEnum(PerformanceReviewQuarter),
  status: z.nativeEnum(PerformanceReviewStatus),
  effectivePeriod: z.object({
    start: z.string(),
    end: z.string(),
  }),
});

export type PerformanceReviewDetailType = z.infer<
  typeof PerformanceReviewDetail
>;
