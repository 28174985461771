import {
  DatePicker,
  DEFAULT_PAGE_SIZE,
  ReasonForLeavingsLazySelect,
} from '@/shared/components';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeOffBoardingFormDataType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Checkbox, Textarea } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const OffBoardingFormFields = () => {
  const {
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue,
    watch,
  } = useFormContext<EmployeeOffBoardingFormDataType>();
  const { hasOffBoardingEditAccess } = useEmployeeAccess();

  const [
    employmentEndDate,
    lastWorkingDate,
    deactivateGreenhouse,
    suspendGoogleAccount,
  ] = watch([
    'employmentEndDate',
    'lastWorkingDate',
    'deactivateGreenhouse',
    'suspendGoogleAccount',
  ]);

  return (
    <fieldset className='form-fieldset lg:!grid-cols-3'>
      <DatePicker
        clearable={hasOffBoardingEditAccess}
        disabled={!hasOffBoardingEditAccess}
        label='Employment End Date'
        error={errors.employmentEndDate?.message}
        placeholder='Select Date'
        required
        value={employmentEndDate || undefined}
        onChange={(value) => {
          setValue(
            'employmentEndDate',
            value
              ? DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                )
              : null,
            {
              shouldDirty: true,
            }
          );
          clearErrors('employmentEndDate');
        }}
      />
      <DatePicker
        clearable={hasOffBoardingEditAccess}
        disabled={!hasOffBoardingEditAccess}
        label='Last Working Date'
        error={errors.lastWorkingDate?.message}
        placeholder='Select Date'
        required
        value={lastWorkingDate || undefined}
        onChange={(value) => {
          setValue(
            'lastWorkingDate',
            value
              ? DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                )
              : null,
            {
              shouldDirty: true,
            }
          );
          clearErrors('lastWorkingDate');
        }}
      />

      <Controller
        control={control}
        name='reasonForLeavingId'
        render={({ field: { ...field } }) => (
          <>
            <ReasonForLeavingsLazySelect
              {...field}
              isMulti={false}
              error={errors.reasonForLeavingId?.message}
              label='Reason for leaving'
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  enabled: null,
                },
              }}
              placeholder='Reason for leaving'
              onChange={(option) => {
                setValue('reasonForLeavingId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
              isClearable={hasOffBoardingEditAccess}
              disabled={!hasOffBoardingEditAccess}
            />
          </>
        )}
      />

      <Textarea
        {...register('detailReasonForLeaving')}
        label='Detail reason for leaving'
        className='col-span-4'
        minRows={5}
        disabled={!hasOffBoardingEditAccess}
      />

      <Checkbox
        error={errors.deactivateGreenhouse?.message}
        checked={deactivateGreenhouse}
        label='Do not deactivate greenhouse account'
        className='col-span-4'
        onChange={(e) => {
          setValue('deactivateGreenhouse', e.currentTarget.checked, {
            shouldDirty: true,
          });
        }}
        disabled={!hasOffBoardingEditAccess}
      />

      <Checkbox
        error={errors.suspendGoogleAccount?.message}
        checked={suspendGoogleAccount}
        label='Do not suspend Google account'
        className='col-span-4'
        onChange={(e) => {
          setValue('suspendGoogleAccount', e.currentTarget.checked, {
            shouldDirty: true,
          });
        }}
        disabled={!hasOffBoardingEditAccess}
      />
    </fieldset>
  );
};
