import {
  CompanyEmailDomainLazySelect,
  CountryLazySelect,
  DatePicker,
  DEFAULT_PAGE_SIZE,
  EmployeeHiringSourceSelect,
  EmployeeLazySelect,
} from '@/shared/components';
import { EmployeeHiringSource } from '@/shared/graphql';
import { EmployeeGeneralInformationFormDataType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Checkbox, Radio, TextInput } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const GeneralInformationFormFields = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<EmployeeGeneralInformationFormDataType>();

  const [isNewGraduate] = watch(['isNewGraduate']);

  return (
    <fieldset className='form-fieldset'>
      <TextInput
        {...register('preferredFullName')}
        error={errors.preferredFullName?.message}
        required
        label='Preferred Full Name'
        placeholder='Preferred Full Name'
      />
      <TextInput
        {...register('legalFullName')}
        error={errors.legalFullName?.message}
        required
        label='Legal Full Name'
        placeholder='Legal Full Name'
      />
      <TextInput
        {...register('nameInLocalLanguage')}
        error={errors.nameInLocalLanguage?.message}
        label='Name in Local Language'
        placeholder='Name in Local Language'
      />
      <TextInput
        {...register('japanesePhoneticName')}
        label='ﾌﾘｶﾞﾅ (Kana of Japanese)'
        placeholder='ﾌﾘｶﾞﾅ (Kana of Japanese)'
      />

      <Controller
        control={control}
        name='nationalityId'
        render={({ field: { ...field } }) => (
          <>
            <CountryLazySelect
              {...field}
              isMulti={false}
              label={'Nationality'}
              required
              error={errors.nationalityId?.message}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  keyword: null,
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  hasCompanies: null,
                },
              }}
              placeholder={'Nationality'}
              onChange={(option) => {
                setValue('nationalityId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name='primaryManagerId'
        render={({ field: { ...field } }) => (
          <>
            <EmployeeLazySelect
              {...field}
              isMulti={false}
              required
              error={errors.primaryManagerId?.message}
              label={'Primary Manager'}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  filter: null,
                },
              }}
              placeholder={'Primary Manager Id'}
              onChange={(option) => {
                setValue('primaryManagerId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name='dottedLineManagerId'
        render={({ field: { ...field } }) => (
          <>
            <EmployeeLazySelect
              isMulti={false}
              label={'Dotted Line Manager'}
              error={errors.dottedLineManagerId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  filter: null,
                },
              }}
              placeholder={'Dotted Line Manager'}
              {...field}
              onChange={(option) => {
                setValue('dottedLineManagerId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name='hiredDate'
        render={({ field: { value, ...field } }) => {
          return (
            <>
              <DatePicker
                {...field}
                required
                label='Hired Date'
                error={errors.hiredDate?.message}
                placeholder='Hired Date'
                value={value || undefined}
                onChange={(value) => {
                  setValue(
                    'hiredDate',
                    DateUtils.formatDate(
                      new Date(value || '').toISOString(),
                      'yyyy-MM-dd'
                    ),
                    {
                      shouldDirty: true,
                    }
                  );
                }}
              />
            </>
          );
        }}
      />

      <div className='flex'>
        <TextInput
          {...register('emailUsername')}
          error={errors.emailUsername?.message}
          className='w-full'
          required
          label='Company Email'
          placeholder='Company Email'
        />
        <span className='mx-2 mt-[25px]'>@</span>
        <Controller
          control={control}
          name='emailDomain'
          render={({ field: { ...field } }) => (
            <>
              <CompanyEmailDomainLazySelect
                {...field}
                isMulti={false}
                label={' '}
                error={errors.emailDomain?.message}
                valueFormatter={(item) => item.id}
                className='select-has-min-w-menu'
                defaultQueryOptions={{
                  variables: {
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    enabled: null,
                  },
                }}
                placeholder={'Email Domain'}
                onChange={(option) => {
                  setValue('emailDomain', option?.value as string, {
                    shouldDirty: true,
                  });
                }}
              />
            </>
          )}
        />
      </div>

      <Controller
        control={control}
        name='needsProbationReview'
        render={({ field: { value, ...fields } }) => {
          return (
            <div className='col-span-2'>
              <Radio.Group
                label='Does he/she need Probation review page?'
                {...fields}
                value={value ? '1' : '0'}
                onChange={(newValue) => {
                  setValue('needsProbationReview', newValue === '1', {
                    shouldDirty: true,
                  });
                }}
              >
                <div className='mt-2 flex gap-x-4'>
                  <Radio className='inline' label={'Yes'} value={'1'} />
                  <Radio className='inline' label={'No'} value={'0'} />
                </div>
              </Radio.Group>
            </div>
          );
        }}
      />

      <Controller
        control={control}
        name='hiringSource'
        render={({ field: { value } }) => (
          <>
            <EmployeeHiringSourceSelect
              isMulti={false}
              error={errors.hiringSource?.message}
              onChange={(newValue) => {
                setValue(
                  'hiringSource',
                  (newValue as { value: EmployeeHiringSource }).value ?? null,
                  {
                    shouldDirty: true,
                  }
                );
              }}
              value={value}
            />
          </>
        )}
      />

      <TextInput
        {...register('greenhouseLink')}
        error={errors.greenhouseLink?.message}
        className='w-full'
        required
        label='Greenhouse Link'
        placeholder='https://'
      />
      <div className='col-span-2 flex'>
        <Checkbox
          error={errors.isNewGraduate?.message}
          checked={isNewGraduate}
          label='新卒採用 (Applies to Employees in Tokyo Office Only)'
          onChange={(e) => {
            setValue('isNewGraduate', e.currentTarget.checked, {
              shouldDirty: true,
            });
          }}
        />
      </div>
    </fieldset>
  );
};
