import { toast } from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { TableSelection } from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useState } from 'react';
import {
  useGenerateGoogleOfflineOAuthUrlLazyQuery,
  useVerifyGoogleOfflineTokenMutation,
} from 'src/pages/employees/list/graphql';
import { AUTH_STATE_KEY } from 'src/pages/login';
import { ApiUtils } from 'src/utils/api.utils';
import { RouterUtils } from 'src/utils/router.utils';
import { getStorageItem } from 'src/utils/storage';
import { useExportPerformanceReviewsLazyQuery } from '../../graphql';
import { PerformanceReviewResultListItem } from '../../types';
import { PerformanceReviewResultListFilterType } from '../performance-review-result-list-filter/PerformanceReviewResultList.filters';

interface Props {
  selection: TableSelection<PerformanceReviewResultListItem>;
  filters: FilterProps<PerformanceReviewResultListFilterType>;
}

export const PerformanceReviewResultListExport = ({
  selection,
  filters,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const [exportPerformanceReviews] = useExportPerformanceReviewsLazyQuery({
    onError(data) {
      toast.error(ApiUtils.getErrorMessage(data));
    },
    onCompleted(data) {
      RouterUtils.externalRedirect(data.exportPerformanceReviews, true);
    },
  });
  const [verifyGoogleOfflineToken] = useVerifyGoogleOfflineTokenMutation();
  const [generateGoogleOfflineOAuthUrl] =
    useGenerateGoogleOfflineOAuthUrlLazyQuery({
      onError(data) {
        toast.error(ApiUtils.getErrorMessage(data));
      },
    });

  const onExport = async () => {
    setLoading(true);
    const offlineToken = await verifyGoogleOfflineToken();

    if (!offlineToken.data?.verifyGoogleOfflineToken) {
      const authState = getStorageItem(AUTH_STATE_KEY);
      const response = await generateGoogleOfflineOAuthUrl({
        variables: {
          state: authState ?? '',
          redirectUri: process.env.REACT_APP_PERFORMANCE_REVIEW_CALLBACK_URL,
        },
      });

      const url = response.data?.generateGoogleOfflineOAuthUrl;
      RouterUtils.externalRedirect(url);
    }

    await exportPerformanceReviews({
      variables: {
        period: {
          year: filters.current.year,
          quarter: filters.current.quarter,
        },
        employeeIds: selection.items.map((s) => s.employee.id),
      },
    });

    setLoading(false);
  };

  return (
    <Button className='bg-blue-6' onClick={onExport} loading={loading}>
      Export
    </Button>
  );
};
