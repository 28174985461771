import { CurrenciesEnum, CurrenciesEnumType } from '../enums';

export class CurrencyUtils {
  static DEFAULT = CurrenciesEnum.JPY;

  static setCurrency(currency?: string) {
    const currentCurrency = CurrencyUtils.asCurrency(currency);
    if (!currentCurrency) return;
  }

  static isCurrency = (currency = ''): currency is CurrenciesEnumType =>
    currency in CurrenciesEnum;

  static asCurrency = (currency?: string | null) =>
    currency && this.isCurrency(currency) ? currency : CurrencyUtils.DEFAULT;
}
