import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportEmployeesQueryVariables = Types.Exact<{
  format: Types.EmployeeExportFormat;
  filter: Types.InputMaybe<Types.ListEmployeesFilterInput>;
  employeeIds: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type ExportEmployeesQuery = { __typename?: 'Query', exportEmployees: any };


export const ExportEmployeesDocument = gql`
    query exportEmployees($format: EmployeeExportFormat!, $filter: ListEmployeesFilterInput, $employeeIds: [ID!]) {
  exportEmployees(format: $format, filter: $filter, employeeIds: $employeeIds)
}
    `;

/**
 * __useExportEmployeesQuery__
 *
 * To run a query within a React component, call `useExportEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportEmployeesQuery({
 *   variables: {
 *      format: // value for 'format'
 *      filter: // value for 'filter'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useExportEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ExportEmployeesQuery, ExportEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportEmployeesQuery, ExportEmployeesQueryVariables>(ExportEmployeesDocument, options);
      }
export function useExportEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportEmployeesQuery, ExportEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportEmployeesQuery, ExportEmployeesQueryVariables>(ExportEmployeesDocument, options);
        }
export function useExportEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportEmployeesQuery, ExportEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportEmployeesQuery, ExportEmployeesQueryVariables>(ExportEmployeesDocument, options);
        }
export type ExportEmployeesQueryHookResult = ReturnType<typeof useExportEmployeesQuery>;
export type ExportEmployeesLazyQueryHookResult = ReturnType<typeof useExportEmployeesLazyQuery>;
export type ExportEmployeesSuspenseQueryHookResult = ReturnType<typeof useExportEmployeesSuspenseQuery>;
export type ExportEmployeesQueryResult = Apollo.QueryResult<ExportEmployeesQuery, ExportEmployeesQueryVariables>;