import { DatePicker } from '@mantine/dates';
import { useState } from 'react';

interface Props {
  value?: Date;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const Calendar = ({ value, onChange, minDate, maxDate }: Props) => {
  const [date, setDate] = useState<Date | undefined>(value);

  return (
    <DatePicker
      value={date}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(date) => {
        if (date) {
          setDate(date);
          onChange(date);
        }
      }}
    />
  );
};
