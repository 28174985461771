import * as Types from '../../../../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationVariables = Types.Exact<{
  input: Types.UpdatePerformanceReviewGrowthTargetForTargetEvaluationInput;
}>;


export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation = { __typename?: 'Mutation', updatePerformanceReviewGrowthTargetForTargetEvaluation: { __typename?: 'PerformanceReviewOutput', id: string } };


export const UpdatePerformanceReviewGrowthTargetForTargetEvaluationDocument = gql`
    mutation UpdatePerformanceReviewGrowthTargetForTargetEvaluation($input: UpdatePerformanceReviewGrowthTargetForTargetEvaluationInput!) {
  updatePerformanceReviewGrowthTargetForTargetEvaluation(input: $input) {
    id
  }
}
    `;
export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationFn = Apollo.MutationFunction<UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation, UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationVariables>;

/**
 * __useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformanceReviewGrowthTargetForTargetEvaluationMutation, { data, loading, error }] = useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation, UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation, UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationVariables>(UpdatePerformanceReviewGrowthTargetForTargetEvaluationDocument, options);
      }
export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationHookResult = ReturnType<typeof useUpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation>;
export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationResult = Apollo.MutationResult<UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation>;
export type UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationOptions = Apollo.BaseMutationOptions<UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutation, UpdatePerformanceReviewGrowthTargetForTargetEvaluationMutationVariables>;