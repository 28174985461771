import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEmployeeListCustomFiltersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListEmployeeListCustomFiltersQuery = { __typename?: 'Query', listEmployeeListCustomFilters: { __typename?: 'ListEmployeeListCustomFilters', totalCount: number, items: Array<{ __typename?: 'EmployeeListCustomFilter', businessUnitIds: Array<string>, companyIds: Array<string>, contractTypes: Array<Types.ContractType>, createdAt: any, createdById: string | null, departmentIds: Array<string>, externalAccountGoogle: Array<Types.EmployeeExternalAccountStatus>, externalAccountGreenhouse: Array<Types.EmployeeExternalAccountStatus>, externalAccountHubspot: Array<Types.EmployeeExternalAccountStatus>, externalAccountSlack: Array<Types.EmployeeExternalAccountStatus>, id: string, isLeave: boolean, isOpenFilter: boolean, isOnSecondment: boolean, jobIds: Array<string>, keyword: string | null, levels: Array<Types.JobHistoryLevel>, locationIds: Array<string>, divisionIds: Array<string>, name: string, nationalityIds: Array<string>, statuses: Array<Types.EmployeeStatus>, updatedAt: any, updatedById: string | null, managerIds: Array<string>, managerIncludesAllConnections: boolean, roles: Array<Types.EmployeeRole>, createdBy: { __typename?: 'Employee', generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredFullName: string } } | null, hiredDate: { __typename?: 'LocalDateRange', start: any | null, end: any | null }, employmentEndDate: { __typename?: 'LocalDateRange', start: any | null, end: any | null } }> } };


export const ListEmployeeListCustomFiltersDocument = gql`
    query listEmployeeListCustomFilters {
  listEmployeeListCustomFilters {
    items {
      businessUnitIds
      companyIds
      contractTypes
      createdAt
      createdBy {
        generalInformation {
          preferredFullName
        }
      }
      createdById
      departmentIds
      externalAccountGoogle
      externalAccountGreenhouse
      externalAccountHubspot
      externalAccountSlack
      hiredDate {
        start
        end
      }
      employmentEndDate {
        start
        end
      }
      id
      isLeave
      isOpenFilter
      isOnSecondment
      jobIds
      keyword
      levels
      locationIds
      divisionIds
      name
      nationalityIds
      statuses
      updatedAt
      updatedById
      managerIds
      managerIncludesAllConnections
      roles
    }
    totalCount
  }
}
    `;

/**
 * __useListEmployeeListCustomFiltersQuery__
 *
 * To run a query within a React component, call `useListEmployeeListCustomFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeListCustomFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeListCustomFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployeeListCustomFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>(ListEmployeeListCustomFiltersDocument, options);
      }
export function useListEmployeeListCustomFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>(ListEmployeeListCustomFiltersDocument, options);
        }
export function useListEmployeeListCustomFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>(ListEmployeeListCustomFiltersDocument, options);
        }
export type ListEmployeeListCustomFiltersQueryHookResult = ReturnType<typeof useListEmployeeListCustomFiltersQuery>;
export type ListEmployeeListCustomFiltersLazyQueryHookResult = ReturnType<typeof useListEmployeeListCustomFiltersLazyQuery>;
export type ListEmployeeListCustomFiltersSuspenseQueryHookResult = ReturnType<typeof useListEmployeeListCustomFiltersSuspenseQuery>;
export type ListEmployeeListCustomFiltersQueryResult = Apollo.QueryResult<ListEmployeeListCustomFiltersQuery, ListEmployeeListCustomFiltersQueryVariables>;