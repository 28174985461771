import { PerformanceReviewStatus } from '@/shared/graphql';
import { Badge } from '@any-ui-react/core';

interface Props {
  status?: PerformanceReviewStatus;
}

export const PerformanceReviewStatusBadge = ({ status }: Props) => {
  switch (status) {
    case PerformanceReviewStatus.TargetEvaluation:
      return <Badge color='blue'>Target Evaluation</Badge>;
    case PerformanceReviewStatus.Closed:
      return <Badge color='green'>Closed for Edits</Badge>;
    default:
      return <Badge color='yellow'>Target Setting</Badge>;
  }
};
