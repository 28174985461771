import { Button, ModalFooter, TextInput } from '@any-ui-react/core';

import { DatePicker } from '@/shared/components';
import { DependentRelation } from '@/shared/graphql';
import {
  DependentFormData,
  DependentFormDataType,
  EmployeeDependent,
  EmployeeDependentType,
} from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Select } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { dependentFormValidationSuite } from './dependent-form.validator';

export interface DependentFormProps {
  dependent?: EmployeeDependentType;
  onSuccess: (data: EmployeeDependentType) => void;
  onCancel: () => void;
}

export const DependentForm = ({
  dependent,
  onSuccess,
  onCancel,
}: DependentFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm<DependentFormDataType>({
    defaultValues: DependentFormData.parse({ ...dependent }),
    resolver: vestResolver(dependentFormValidationSuite),
  });

  const [dateOfBirth] = watch(['dateOfBirth']);

  const dependentOptions = Object.values(DependentRelation).map((value) => ({
    label: StringUtils.capitalizeAll(value, '_'),
    value: value,
  }));

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmployeeDependent.parse({ ...formValues, id: dependent?.id })
        );
      }),
    [dependent?.id, handleSubmit, onSuccess]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          required
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Name'}
          className='col-span-2'
        />

        <Select
          {...register('relation')}
          data={dependentOptions}
          className='col-span-2'
          defaultValue={dependent?.relation}
          error={errors.relation?.message}
          label={'Relation'}
          placeholder={'Enter relation'}
          onChange={(value) => {
            setValue('relation', value as DependentRelation);
            clearErrors('relation');
          }}
          withinPortal
          required
        />

        <div className='z-10 col-span-2'>
          <DatePicker
            error={errors.dateOfBirth?.message}
            label='Date of Birth'
            required
            placeholder='Select Date of Birth'
            value={dateOfBirth || undefined}
            onChange={(value) => {
              setValue(
                'dateOfBirth',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('dateOfBirth');
            }}
          />
        </div>
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
