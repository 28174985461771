import { Card, EmployeeRoleRadioGroup } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { EmployeeRole, GetEmployeeDocument } from '@/shared/graphql';
import { useEmployeeAccess, useUpdateEmployeePermission } from '@/shared/hooks';
import { apolloClient } from '@/shared/providers';
import {
  EmployeePermissionFormData,
  EmployeePermissionFormDataType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';

import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyEmployeeAssignment } from './CompanyEmployeeAssignment';
import { CompanyEmployeeAssignmentDetails } from './CompanyEmployeeAssignmentDetails';
import { EmployeeRoleDetails } from './EmployeeRoleDetails';
import { permissionFormValidator } from './permission-form.validator';

export const Permission = ({
  onFormStateChange,
}: {
  onFormStateChange?: (isDirty: boolean) => void;
}) => {
  const { employeeDetail } = useEmployeeDetailContext();
  const { hasPermissionEditAccess } = useEmployeeAccess();
  const form = useForm<EmployeePermissionFormDataType>({
    defaultValues: {
      ...EmployeePermissionFormData.parse({
        role: employeeDetail.permission.role,
        employeeIds: employeeDetail.permission.employeeIds,
        companyIds: employeeDetail.permission.companyIds,
        employees: employeeDetail.permission.employees,
        isAllEmployees: employeeDetail.permission.employees.length === 0,
      }),
    },
    resolver: vestResolver(permissionFormValidator),
  });
  const selectedRole = form.watch('role');
  const shouldShowCompanyAssignment = useMemo(
    () =>
      [
        EmployeeRole.SuperAdmin,
        EmployeeRole.Basic,
        EmployeeRole.Advanced,
      ].includes(selectedRole),
    [selectedRole]
  );

  const { handleUpdateEmployeePermission, loading } =
    useUpdateEmployeePermission({
      onCompleted: () => {
        form.reset(
          { ...form.getValues() },
          {
            keepValues: false,
            keepDirty: false,
            keepDefaultValues: false,
          }
        );
        apolloClient.refetchQueries({
          include: [GetEmployeeDocument],
        });
      },
    });

  useEffect(() => {
    onFormStateChange?.(form.formState.isDirty);
  }, [form.formState.isDirty, onFormStateChange]);

  return (
    <Card withPadding={false}>
      <FormProvider {...form}>
        <form
          noValidate
          onSubmit={form.handleSubmit((formData) => {
            handleUpdateEmployeePermission(formData, employeeDetail.id);
          })}
        >
          <div className='p-6'>
            {hasPermissionEditAccess ? (
              <>
                <EmployeeRoleRadioGroup />
                {!shouldShowCompanyAssignment && <CompanyEmployeeAssignment />}
              </>
            ) : (
              <>
                <EmployeeRoleDetails
                  role={employeeDetail.permission.role ?? ''}
                />
                {!shouldShowCompanyAssignment && (
                  <CompanyEmployeeAssignmentDetails employee={employeeDetail} />
                )}
              </>
            )}
          </div>
          <hr />
          {hasPermissionEditAccess && (
            <div className='flex px-6 pb-6 pt-4'>
              <Button
                className='ml-auto bg-blue-6'
                type='submit'
                loading={loading}
              >
                Save
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};
