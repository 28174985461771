import { GoogleLogoIcon, GreenhouseLogoIcon } from '@/shared/components';
import { ExternalAccountTimingToCreate } from '@/shared/graphql';
import { EmployeeAddFormDataType } from '@/shared/types';
import { Checkbox, Radio } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const ExternalAccount = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<EmployeeAddFormDataType>();

  const [autoCreateGoogleWorkspace, autoCreateGreenhouse] = watch([
    'autoCreateGoogleWorkspace',
    'autoCreateGreenhouse',
  ]);

  return (
    <fieldset className='form-fieldset'>
      <Controller
        control={control}
        name='timingToCreateAccounts'
        render={({ field: { value, ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              value={value ?? ExternalAccountTimingToCreate.SevenDaysBefore}
              label='Timing to create accounts'
              className='col-span-2'
            >
              <div className='mt-4 flex gap-x-4'>
                <Radio
                  className='inline'
                  label='Today'
                  value={ExternalAccountTimingToCreate.Today}
                />
                <Radio
                  className='inline'
                  label={'7 days before the planned hired date'}
                  value={ExternalAccountTimingToCreate.SevenDaysBefore}
                />
              </div>
            </Radio.Group>
          );
        }}
      />
      <div className='col-span-2'>
        <p className='mb-4'>Auto-create accounts for platforms below.</p>
        <div className='flex'>
          <Checkbox
            wrapperProps={{ classNames: 'sss' }}
            error={errors.autoCreateGoogleWorkspace?.message}
            checked={autoCreateGoogleWorkspace}
            label={
              <div className='flex'>
                <GoogleLogoIcon />
                <span className='ml-1'>Google Workspace</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateGoogleWorkspace', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />

          <Checkbox
            className='ml-10'
            error={errors.autoCreateGreenhouse?.message}
            checked={autoCreateGreenhouse}
            label={
              <div className='flex'>
                <GreenhouseLogoIcon />
                <span className='ml-1'>Greenhouse</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateGreenhouse', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />
        </div>
      </div>
    </fieldset>
  );
};
