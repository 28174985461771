import { ProbationReviewResult } from '@/shared/graphql';
import { Badge } from '@any-ui-react/core';

interface Props {
  result?: ProbationReviewResult;
}

export const ProbationResult = ({ result }: Props) => {
  if (result === ProbationReviewResult.Passed) {
    return <Badge color='green'>Pass</Badge>;
  }

  if (result === ProbationReviewResult.Hold) {
    return <Badge color='red'>Needs More Consideration</Badge>;
  }

  return null;
};
