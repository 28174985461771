import { NoResult, Table, TableLayout, toast } from '@/shared/components';
import { PerformanceReviewStatus } from '@/shared/graphql';
import { ApiUtils } from '@/shared/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterProps } from 'src/hooks/shared';
import { useBulkUpdatePerformanceReviewStatusMutation } from '../../graphql/mutation';
import {
  PerformanceReviewStageList,
  PerformanceReviewStageListItem,
} from '../../types';
import { PerformanceReviewStageListFilterType } from '../performance-review-stage-list-filter/PerformanceReviewStageList.filters';
import { PerformanceReviewStatusRadioButton } from './PerformanceReviewStatusRadioButton';

interface Props {
  data: PerformanceReviewStageList;
  loading: boolean;
  page: number;
  filters: FilterProps<PerformanceReviewStageListFilterType>;
}

export const PerformanceReviewStageListTable = ({
  data,
  loading,
  page,
  filters,
}: Props) => {
  const [list, setList] = useState(data);
  const [updateStatus] = useBulkUpdatePerformanceReviewStatusMutation({
    onCompleted: () => {
      toast.success('Status successfully updated');
    },
    onError: (error) => {
      toast.error(ApiUtils.getErrorMessage(error));
    },
  });

  useEffect(() => {
    setList(data);
  }, [data]);

  const onStatusUpdate = useCallback(
    async (companyId: string, status: PerformanceReviewStatus) => {
      // Hold the current state to revert to if saving fails
      const currentList = [...list];

      // Optimistically update the current table
      setList((prev) =>
        prev.map((item) =>
          item.companyId === companyId ? { ...item, status } : item
        )
      );

      const response = await updateStatus({
        variables: {
          input: {
            period: {
              quarter: filters.current.quarter,
              year: filters.current.year,
            },
            companyId,
            status,
          },
        },
      });

      // If saving failed, revert to the previous list
      if (response.errors) {
        setList(currentList);
      }
    },
    [filters, list, updateStatus]
  );

  const layout: TableLayout<PerformanceReviewStageListItem>[] = useMemo(
    () => [
      {
        headerRender: () => 'Year',
        accessor: 'year',
        cellRender: ({ period }) => period.year,
      },
      {
        headerRender: () => 'Quarter',
        accessor: 'quarter',
        cellRender: ({ period }) => period.quarter.replace('_', '/'),
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ company }) => company.name,
      },
      {
        headerRender: () => (
          <div className='flex w-full justify-center'>Target setting stage</div>
        ),
        accessor: 'targetSetting',
        cellRender: ({ companyId, status }) => (
          <PerformanceReviewStatusRadioButton
            selected={status === PerformanceReviewStatus.TargetSetting}
            onClick={() =>
              onStatusUpdate(companyId, PerformanceReviewStatus.TargetSetting)
            }
          />
        ),
      },
      {
        headerRender: () => (
          <div className='flex w-full justify-center'>Assessment stage</div>
        ),
        accessor: 'assessment',
        cellRender: ({ companyId, status }) => (
          <PerformanceReviewStatusRadioButton
            selected={status === PerformanceReviewStatus.TargetEvaluation}
            onClick={() =>
              onStatusUpdate(
                companyId,
                PerformanceReviewStatus.TargetEvaluation
              )
            }
          />
        ),
      },
      {
        headerRender: () => (
          <div className='flex w-full justify-center'>Lock stage</div>
        ),
        accessor: 'lock',
        cellRender: ({ companyId, status }) => (
          <PerformanceReviewStatusRadioButton
            selected={status === PerformanceReviewStatus.Closed}
            onClick={() =>
              onStatusUpdate(companyId, PerformanceReviewStatus.Closed)
            }
          />
        ),
      },
    ],
    [onStatusUpdate]
  );

  return (
    <Table<PerformanceReviewStageListItem>
      page={page}
      layout={layout}
      loading={loading}
      data={list}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
    />
  );
};
