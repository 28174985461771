import { Card, PageTitle } from '@/shared/components';
import { useFilters } from '@/shared/hooks';
import {
  NumberOfEmployeesFilter as NumberOfEmployeesFilterParser,
  NumberOfEmployeesFilterType,
  ReportNumberOfEmployeesTab,
} from '@/shared/types';
import { NumberOfEmployeesTabs } from './components';

export const NumberOfEmployees = () => {
  const filters = useFilters<NumberOfEmployeesFilterType>(
    {
      tab: ReportNumberOfEmployeesTab.ALL,
      year: new Date().getFullYear().toString(),
      contractTypes: [],
    },
    {
      parser: NumberOfEmployeesFilterParser.parse,
    }
  );

  return (
    <>
      <PageTitle text='Number of Employees' />
      <Card withPadding={false}>
        <NumberOfEmployeesTabs filters={filters} />
      </Card>
    </>
  );
};
