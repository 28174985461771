import classNames from 'classnames';
import { StringUtils } from 'src/utils/string.utils';
import { Card } from '../card';
import { UserSettingIcon } from '../icons';

interface Props {
  pictureUrl: string;
  legalFullName: string;
  employeeId: string;
  nameInLocalLanguage: string;
  company: string;
  department: string;
  jobTitle: string;
  extra?: React.ReactNode;
  aside?: React.ReactNode;
}

export const EmployeeHeader = ({
  pictureUrl,
  legalFullName,
  employeeId,
  nameInLocalLanguage,
  company,
  department,
  jobTitle,
  extra,
  aside,
}: Props) => {
  return (
    <Card className='flex w-full items-center justify-between'>
      <div>
        <div className='inline-block h-16 max-h-16 w-16 border'>
          {pictureUrl ? (
            <img
              src={pictureUrl}
              alt={legalFullName}
              className='h-16 w-16'
              referrerPolicy='no-referrer'
            />
          ) : (
            <UserSettingIcon className='h-16 w-16' />
          )}
        </div>
        <div className='ml-6 inline-block'>
          <div
            className={classNames({ 'flex items-center space-x-4': !!extra })}
          >
            <span className='block h-6 text-2xl font-semibold !leading-[normal]'>
              {legalFullName} #{employeeId}
            </span>
            {extra}
          </div>
          <span className='my-2 block h-3.5 !leading-[normal] text-gray-7'>
            {StringUtils.withFallback(nameInLocalLanguage)}
          </span>
          <span className='block h-3 text-xs !leading-[normal]'>
            {`${company} - ${department} - ${jobTitle}`}
          </span>
        </div>
      </div>
      {aside}
    </Card>
  );
};
