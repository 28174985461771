import { EmployeeExternalAccountStatusSelect } from '@/shared/components';
import { StringUtils } from '@/shared/utils';
import { ExternalAccountsFilterType } from './ExternalAccounts.filters';

export const ExternalAccountFilters = ({
  externalAccountsFilter,
  setExternalAccountsFilter,
}: {
  externalAccountsFilter: ExternalAccountsFilterType;
  setExternalAccountsFilter: React.Dispatch<
    React.SetStateAction<ExternalAccountsFilterType>
  >;
}) => {
  return (
    <div className='py-3'>
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Google'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            google: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.google.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Slack'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            slack: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.slack.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='HubSpot'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            hubspot: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.hubspot.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Greenhouse'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            greenhouse: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.greenhouse.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
    </div>
  );
};
