import { useMemo } from 'react';

import { BusinessUnitType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { BusinessUnitModal } from '../business-unit-modal';

interface BusinessUnitTableProps {
  loading: boolean;
  items: BusinessUnitType[];
}

export const BusinessUnitTable = ({
  items,
  loading,
}: BusinessUnitTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (businessUnit: BusinessUnitType) => {
            return (
              <BusinessUnitModal businessUnit={businessUnit}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {businessUnit.name}
                </span>
              </BusinessUnitModal>
            );
          },
        },
        {
          headerRender: () => 'Division',
          accessor: 'divisionId',
          cellRender: ({ division }: BusinessUnitType) => division.name,
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: BusinessUnitType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: BusinessUnitType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: BusinessUnitType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<BusinessUnitType>[],
    []
  );

  const tableCommonProps: TableProps<BusinessUnitType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Business Unit Item Title'}
        description={'There are no Business Unit Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<BusinessUnitType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
