import { Card, Spinner } from '@/shared/components';
import { Tabs } from '@any-ui-react/core';
import { Suspense, lazy } from 'react';
import {
  PerformanceReviewTab,
  PerformanceReviewTabLabels,
  usePerformanceReviewFilter,
} from '../performance-review-filter';

const PerformanceValue = lazy(() =>
  import('../performance-value').then((module) => ({
    default: module.PerformanceValue,
  }))
);
const PerformanceOverview = lazy(() =>
  import('../performance-overview').then((module) => ({
    default: module.PerformanceOverview,
  }))
);
const PerformanceTarget = lazy(() =>
  import('../performance-target').then((module) => ({
    default: module.PerformanceTarget,
  }))
);
const PerformanceSetting = lazy(() =>
  import('../performance-setting').then((module) => ({
    default: module.PerformanceSetting,
  }))
);
const PerformanceGrowthTarget = lazy(() =>
  import('../performance-growth-target').then((module) => ({
    default: module.PerformanceGrowthTarget,
  }))
);

export const PerformanceReviewEditTabs = () => {
  const TabComponents = {
    [PerformanceReviewTab.OVERVIEW]: PerformanceOverview,
    [PerformanceReviewTab.GROWTH_TARGET]: PerformanceGrowthTarget,
    [PerformanceReviewTab.VALUE]: PerformanceValue,
    [PerformanceReviewTab.PERFORMANCE_TARGET]: PerformanceTarget,
    [PerformanceReviewTab.SETTING]: PerformanceSetting,
  };

  const { filters } = usePerformanceReviewFilter();

  // TODO! RBAC
  const tabs = [
    PerformanceReviewTab.OVERVIEW,
    PerformanceReviewTab.GROWTH_TARGET,
    PerformanceReviewTab.VALUE,
    PerformanceReviewTab.PERFORMANCE_TARGET,
    PerformanceReviewTab.SETTING,
  ].map((value) => ({
    tab: value,
    value: value,
  }));

  return (
    <Tabs
      value={filters.current.tab}
      dir='ltr'
      className={'tab-wrapper'}
      orientation='horizontal'
      keepMounted={false}
      onTabChange={(value) => filters.changeFilter('tab', value)}
    >
      {tabs.length > 1 && (
        <Card withPadding={false}>
          <Tabs.List>
            {tabs.map((tab) => (
              <Tabs.Tab key={tab.value} value={tab.value}>
                {PerformanceReviewTabLabels[tab.tab]}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Card>
      )}

      {tabs.map((tab) => {
        const Component = TabComponents[tab.value];
        return (
          <Tabs.Panel key={tab.value} value={tab.value}>
            <div>
              <Suspense
                fallback={
                  <div className='flex w-full items-center justify-center py-11'>
                    <Spinner />
                  </div>
                }
              >
                <Component />
              </Suspense>
            </div>
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};
