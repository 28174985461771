import { z } from 'zod';

export const EmployeeOffBoarding = z.object({
  employeeId: z.string().default(''),
  detailReasonForLeaving: z.string().nullable().default(''),
  employmentEndDate: z.string().nullable().default(''),
  lastWorkingDate: z.string().nullable().default(''),
  reasonForLeavingId: z.string().nullable().default(null),
  deactivateGreenhouse: z.boolean().default(false),
  suspendGoogleAccount: z.boolean().default(false),
});
export type EmployeeOffBoardingType = z.infer<typeof EmployeeOffBoarding>;
